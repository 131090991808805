// React Imports
import React, { ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
// Store Imports
import { RootState } from '../store/store';
// Third party Imports
import { faClose, faSignOut } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Drawer, Space } from 'antd';

export const SideDrawer: React.FC<any> = (props: any) => {
  const user: any = useSelector<RootState>((state)=> state?.user?.user);
  const selectedOrg: any = useSelector<RootState>((state)=> state?.user?.org);
  const navigate = useNavigate();

  const renderTitle = (): ReactNode => {
    return (
        <div className='flex justify-between flex-row'>
            <div className='flex items-center'>
                <div>
                  <img src={user?.photoURL} style={{width:'2.3rem'}} className=' cursor-pointer rounded-3xl'></img>
                </div>
                <div className=' justify-self-center'>
                  <span className=' ml-2 block'>{user?.displayName as string}</span>
                </div>
            </div>
            <div>
              <span className='relative top-1 cursor-pointer text-black right-1' onClick={()=>props.setShowDrawer(false)}>
                <FontAwesomeIcon icon={faClose}/>
              </span>
            </div>
        </div>
    )
  }

  const renderFooter= () : ReactNode => {
    return (
        <div className='flex justify-end flex-row'>
          <div className='px-4 py-2 hover:bg-gray-100 cursor-pointer text-black text-sm' onClick={props.signOut}>
            <span className='mr-1'><FontAwesomeIcon icon={faSignOut} /></span>
            <span >Sign out</span>
          </div>
        </div>
    )
  }


  return (
    <>
      <Drawer placement="right" style={{width:'378px', height: 'calc(100vh)'}}  closeIcon = {false} onClose={()=> props.setShowDrawer(false)} open={props.showDrawer}
        title = {renderTitle()}
        footer = {renderFooter()}
      >
        <div className=' px-2 text-inherit flex col self-center ml-2 text-xs items-center underline cursor-pointer'>
          <div className='text-inherit flex items-center'>
              <img src={selectedOrg?.avatar_url} style={{width:'2rem'}} className=' rounded-3xl border-solid border-2'></img>
              <strong style={{paddingLeft:'0.5rem'}}>{selectedOrg?.login as string}</strong>
          </div>
          <p className='block' style={{fontSize:'1rem'}} onClick={()=>{navigate("/")}}>Choose different organization </p>
        </div>
      </Drawer>
    </>
  );
};

//     <div className='absolute top-0 right-0 p-2 bg-white border rounded shadow w-1/5 h-full' style={{'zIndex':99999}}>

        // <span className='relative top-1 text-black right-1'><FontAwesomeIcon icon={faClose}/></span>
        // <div className=' px-2 text-inherit flex align-middle'>
        //     <div><img src={user?.photoURL} style={{width:'2.3rem'}} className=' cursor-pointer rounded-3xl'></img></div>
        //     <div>
        //         <strong style={{paddingTop: '0.4rem', paddingLeft:'0.5rem'}}>{user?.displayName as string}</strong>
        //         <span></span>
        //     </div>

        // </div>
      // <div className='px-4 py-2 hover:bg-gray-100 cursor-pointer text-black text-sm'>
      //   <span className='mr-1'><FontAwesomeIcon icon={faSignOut} /></span>
      //   <span >Sign out</span>

      // </div>
//     </div>
//   );
// }

// export default DropdownMenu;
