import * as go from "gojs";
import {focusNode, parsedLocation, unfocusNode} from "../pages/canvas/goHelper";
import textBlocks from "../pages/canvas/components/textBlocks";
import {flagToggle, showSuccessToast} from "../pages/canvas/helper";
import placeholder from "../pages/canvas/components/placeholders";
import {GraphObject} from "gojs";
import clickFn from "../pages/canvas/actions/click";
import createPorts from "../pages/canvas/actions/createPorts";
import showLinks from "../pages/canvas/actions/showLinks";
import {Store} from "@reduxjs/toolkit";
import {RefObject} from "react";


const apiPanelExpander = (e: go.InputEvent, obj: go.GraphObject) => {
    let collapsible = obj?.part?.findObject("apiPanel");
    if (collapsible) collapsible.visible = !collapsible.visible;
}

export const verticalGroupLayout = (
    $: typeof GraphObject.make,
    expansionHandler: (e: any, obj: any) => Promise<void>,
    focusToSelectedApi: (e: any, node: any) => void) => {
    return $(go.Group, "Auto", {
            layout: $(go.GridLayout, {
                wrappingColumn: 2,
                alignment: go.GridLayout.Position,
                cellSize: new go.Size(1, 1),
                spacing: new go.Size(10, 10)
            })
        },
        parsedLocation(),
        new go.Binding("isSubGraphExpanded", "isSubGraphExpanded"),
        $(go.Shape, "RoundedRectangle", {fill: "#6e99f5", stroke: "#00897b"}),
        $(go.Panel, 'Vertical', {padding: 1},
            $(go.Panel, "Vertical", {defaultAlignment: go.Spot.Left, stretch: go.GraphObject.Fill},
                $(go.Panel, "Horizontal", {
                        defaultAlignment: go.Spot.Top,
                        padding: new go.Margin(8, 4),
                        minSize: new go.Size(200, 40),
                    },
                    $("SubGraphExpanderButton", {
                        click: (e: any, obj: any) => {
                            expansionHandler(e, obj)
                        }
                    }),
                    textBlocks.application(),
                ),
                $(go.Panel, "Table",
                    new go.Binding("visible", "itemArray", (itemArray) => Array.isArray(itemArray) && itemArray.length > 0),
                    {
                        stretch: go.GraphObject.Horizontal,
                        background: "#2774e3",
                        visible: false,
                        padding: 5,
                        cursor: "pointer",
                        click: apiPanelExpander
                    },
                    $(go.RowColumnDefinition, {column: 0, stretch: go.GraphObject.Horizontal}),
                    $(go.TextBlock, "APIs",
                        {
                            column: 0,
                            alignment: go.Spot.Left,
                            font: "10pt Verdana, sans-serif",
                            stroke: "white",
                            margin: new go.Margin(0, 5, 0, 5)
                        }
                    ),
                    $("PanelExpanderButton", "apiPanel",  // name of the object
                        {
                            column: 1,
                            alignment: go.Spot.Right,
                            "ButtonIcon.stroke": "white",
                            margin: new go.Margin(0, 5, 0, 5),
                        }
                    )),
                    $(go.Panel, "Vertical",
                        {
                            name: "apiPanel",
                            visible: false
                        },
                        // new go.Binding("visible", "itemArray", flagToggle),
                        new go.Binding( "itemArray"),
                        {
                            stretch: go.GraphObject.Fill,
                            background: '#e0f2f1',
                            itemTemplate: $(go.Panel, 'Auto', {
                                    alignment: go.Spot.Left,
                                    margin: new go.Margin(4),
                                    stretch: go.GraphObject.Fill,
                                },
                                $(go.Shape, 'RoundedRectangle',
                                    {fill: 'white', strokeWidth: 0, stroke: "#458ef5", name: "SHAPE"}
                                ),
                                textBlocks.apiGroup(), {
                                    doubleClick: (e: any, node: any) => {
                                        // Expand, Collapse Api Panel and Focus to Node
                                        expansionHandler(e, node).then(() => {
                                            const apiPanel = node.part.findObject("apiPanel");
                                            if (apiPanel) apiPanel.visible = false
                                            focusToSelectedApi(e, node)
                                        })
                                    },
                                    mouseEnter: focusNode,
                                    mouseLeave: unfocusNode,
                                })
                        })), placeholder.subgraph()));
}

export const defaultNode = (
    $: typeof GraphObject.make,
    mapLinksForSelectedNode: (e: any, node: any) => void,
    canvasRef: RefObject<any>,
    store: Store,
    diagram: go.Diagram
) => {
    let backup: any = [];

    return $(go.Node, "Auto",
        {zOrder: 1},
        $(go.Shape, "RoundedRectangle", {stroke: "#424242"}, new go.Binding("fill", "color")),
        $(go.Panel, "Auto",
            $(go.Panel, "Vertical",
                textBlocks.defaultgroup(),
                $("Panel", "Horizontal", $(go.TextBlock, {text: '\uf24d', font: '18px FontAwesome',}),
                    {
                        cursor: "pointer", margin: 2, alignment: go.Spot.TopRight, name: "CopyButton",
                        visible: false,
                        click: (e: any, obj: any) => {
                            navigator.clipboard.writeText(obj.part.data.text)
                            showSuccessToast("Code copied to clipboard");
                        },
                    }),
                $(go.Panel, "Spot", {
                        name: "CodePanel",
                        stretch: go.GraphObject.None,
                        visible: false
                    }, textBlocks.node(),
                ),
            )
        ), {
            toolTip: $("ToolTip", textBlocks.tooltip()),
            click: function (e: any, node: any) {
                mapLinksForSelectedNode(e, node)
            },
            doubleClick: function (e: any, node: any) {
                const copyButton = node.findObject("CopyButton")
                const codePanel = node.findObject("CodePanel")
                // check if it's not already expanded, if not then continue
                if (clickFn(codePanel, copyButton)) {
                    e.diagram.startTransaction("test")
                    createPorts(node, null, backup, canvasRef, store);
                    e.diagram.commitTransaction("test")
                    e.diagram.model.linkDataArray = []
                    showLinks(node, e.diagram, store)
                    e.diagram.startTransaction("test")
                    createPorts(node, null, backup, canvasRef, store);
                    e.diagram.commitTransaction("test")
                    e.diagram.model.linkDataArray = []
                    showLinks(node, e.diagram, store)
                    node.zOrder = 100 - node.zOrder;
                    // no need to zoom
                } else {
                    codePanel.visible = false;
                    copyButton.visible = false
                }
            }
        }
    );
}

export const tableLayout = (
    $: typeof GraphObject.make
) => {
    return $(go.Group, "Auto", {
            isSubGraphExpanded: false, // Minimize All Groups on Opening
            layout: $(go.GridLayout, {
                wrappingColumn: 2,
                alignment: go.GridLayout.Position,
                cellSize: new go.Size(1, 1),
                spacing: new go.Size(10, 10)
            })
        },
        // parsedLocation(),
        $(go.Shape, "RoundedRectangle", {fill: "transparent", stroke: "#00897b"}),
        $(go.Panel, "Vertical",
            {
                defaultStretch: go.GraphObject.Horizontal,
                alignment: go.Spot.TopLeft,
            },
            // The header
            $(go.Panel, "Table",
                {padding: new go.Margin(8, 4), background: "#74a2f2"},
                new go.Binding("background", "color"),
                $(go.Panel, "Vertical",
                    $(go.Panel, "Horizontal", $("SubGraphExpanderButton", {}),
                        textBlocks.header())
                )
            ),
            $(go.Panel, "Auto", {background: "#fff"}, placeholder.node()),
        ),
        {
            toolTip: $("ToolTip", textBlocks.tooltip()),
        });
}
export const borderNode = ($: typeof GraphObject.make) => {
    return $(go.Node, "Table", {width: 1000},
        $(go.Panel, "Auto", {
                stretch: go.GraphObject.Fill,
                margin: 4,
            },
            new go.Binding("row"),
            new go.Binding("column", "col"),
            $(go.Shape, "MinusLine", {height: 1, margin: 4, fill: null}),
        ));
}


