// Third party Imports
import * as go from 'gojs';
/**
 * Following function is used to return parsed location binding
 */
export const parsedLocation = () =>
  (new go.Binding('location', 'loc', go.Point.parse))

/**
 * Following function is used to unfocus node
 * using passed data object
 * @param e event
 * @param node object
 * @return string
 */
export const unfocusNode = (e: any, node: any) => {
  const shape = node.findObject('SHAPE');

  if (shape) {
    shape.strokeWidth = 0;
  }
}

/**
 * Following function is used to focus node
 * using passed data object
 * @param e event
 * @param node object
 * @return string
 */
export const focusNode = (e: any, node: any) => {
  const shape = node.findObject('SHAPE');

  if (shape) {
    shape.strokeWidth = 2;
  }
}

/**
 * Following function is used to return overview
 * @return element
 */
export const initOverview = () => {
  const $ = go.GraphObject.make;

  return $(go.Overview, { contentAlignment: go.Spot.Center });
};
