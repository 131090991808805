import {getUserOrgs} from '../../services/github-service';
import {useEffect} from 'react';
import {useQuery} from 'react-query';
import {OrgsModal} from './components/orgs-modal';
import {useSelector} from 'react-redux';
import {RootState} from '../../store/store'
import {toast} from 'react-toastify';
import {ReposList} from './components/repos-list';


export const Home = () => {
    const selectedOrg = useSelector<RootState>((state) => state?.user?.org);
    const {isError, error, data} = useQuery('orgs', getUserOrgs);

    useEffect(() => {
        if (error) {
            toast("Something went wrong.", {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
                theme: "colored",
                type: "error"
            })
        }
    }, [isError]);

    return (
        <>
            {data && !selectedOrg && <OrgsModal
                orgs={data}
            />}
            {selectedOrg && <ReposList/>}
        </>
    )
}
