import React, {useEffect, useCallback, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useDispatch} from 'react-redux';
import {getUserOrgs} from '../../services/github-service';
import {auth, provider} from '../../firebase';
import {GithubAuthProvider, signInWithPopup} from "firebase/auth";
import {GetStartedModal} from './components/GetStartedModal';
import {toast} from 'react-toastify';
import {Navbar} from "./components/Navbar";
import SettingUpSection from "./components/SettingUpSection";
import GetStartedButton from "./components/GetStartedButton";

export const Landing = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoadedOnce, setLoaderFlag] = useState(false)
    const [isLoggingIn, setIsLoggingIn] = useState(false)
    const [page, setPage] = useState('getting_started')

    const handleSetPage = (page: string) => {
        setPage(page)
    }


    /**
     * Function is used to set logged in user in local storage
     * with expiry defined
     * @param key identificaution for logged in user
     * @param value record to be saved
     * @param ttl time to live in localStorage
     * @return void
     */
    const setLoggedInUserWithExpiry = useCallback((key: string, value: any, ttl: number) => {
        const now = new Date()
        const item = {
            value: value,
            expiry: now.getTime() + ttl,
        }

        localStorage.setItem(key, JSON.stringify(item))
    }, [])


    /**
     * Following hook is used to handle use case for sign out
     * if user explicitly try to navigate to login page using address bar etc
     * then user should be logged out and respective record should be removed
     * from localStorage and store. This should work only on 1st render
     */
    useEffect(() => {
        if (!isLoadedOnce) {
            setLoaderFlag(true)
            removeLoggedInUser('githubUser')
            removeLoggedInUser('githubToken')
            dispatch({type: 'SIGN_OUT'})
        }
    }, []);


    /**
     * Function is used to set logging state flag
     * @param flag true/false based on if user logging in or not
     * @return void
     */
    const setLoginFlag = useCallback((flag: boolean) => {
        setIsLoggingIn(flag)
    }, [])

    /**
     * Function is used to remove logged in user in local storage
     * @param key identificaution for logged in user
     * @return void
     */
    const removeLoggedInUser = useCallback((key: string) => {
        localStorage.removeItem(key)
    }, [])

    /**
     * Following function is used to show repective error toast message
     * @param code error code for respective message
     * @return void
     */
    const setErrorMessage = useCallback((code: string) => {
        if (code === 'auth/popup-closed-by-user' || code === "auth/user-cancelled") {
            toast('Popup closed by user', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
                theme: "colored",
                type: "error"
            })
        } else {
            toast('Something went wrong', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
                theme: "colored",
                type: "error"
            })
        }
    }, [])

    /**
     * Following function is used to deal with redirection
     * to Home page
     * @return void
     */
    const redirectToHome = useCallback(() => {
        navigate('/');
    }, [])

    /**
     * Following function is used to handle github login
     * @return void
     */
    const initiateLogin = useCallback(() => {
        provider.addScope('repo admin:org');
        signInWithPopup(auth, provider)
            .then((result: any) => {
                const credential = GithubAuthProvider.credentialFromResult(result);

                if (credential) {
                    const token = credential.accessToken;// This gives you a GitHub Access Token. You can use it to access the GitHub API.
                    if (result && result.user) {
                        setLoggedInUserWithExpiry('githubUser', result.user, 86400000)
                        dispatch({
                            type: 'ADD_USER',
                            payload: result?.user.toJSON()
                        })
                    }

                    if (token && token.toString().trim().length > 0) {
                        setLoggedInUserWithExpiry('githubToken', token, 86400000)
                        dispatch({
                            type: 'ADD_GITHUB_TOKEN',
                            payload: token
                        })
                    }
                    // Redirect to home page
                    const redirectUrl = getLocalStorageWithoutExpiry('redirectUrl')
                    if (redirectUrl && redirectUrl.pathname.trim().length > 0 && redirectUrl.search.trim().length > 0) {
                        navigate(redirectUrl.pathname + redirectUrl.search);
                    } else {
                        getUserOrgs()
                            .then((res1: any) => {
                                if (res1 && res1[0]) {
                                    navigate("/canvas?mapID=" + res1[0].login);
                                } else {
                                    redirectToHome()
                                }
                            }).catch((err: any) => {
                            redirectToHome()
                        })
                    }
                }
            }).catch((error: any) => {
            setErrorMessage(error.code)
            setIsLoggingIn(false)
        });
    }, []);

    /**
     * Following function is used to get localStorage
     * without expiry
     * @return null/value
     */
    const getLocalStorageWithoutExpiry = (key: string) => {
        // Following if check returns null if any of input is missing
        if (key.trim().length < 1) {
            return null
        }

        const itemStr = localStorage.getItem(key.trim())
        // returns null if item missing
        if (!itemStr || itemStr.toString().length < 1) {
            return null
        }

        try {
            const item = JSON.parse(itemStr)

            return item.value
        } catch (e: any) {
            return null
        }
    }
    return (
        <>
            <Navbar initiateLogin={initiateLogin}
                    setIsLoggingIn={setLoginFlag}
                    isLoggingIn={isLoggingIn}/>
            <div className="bg-gradient-to-r from-green-100 to-blue-100 min-h-screen">
                {
                    page == 'getting_started' && (<>
                        <div className="flex flex-col md:flex-row items-center justify-center">
                            <div className="text-center md:w-1/2 p-16 ">
                                <HeroSection/>
                                <GetStartedModal handleSetPage={handleSetPage}/>
                            </div>
                            <div className="w-full md:w-1/2 p-4 md:flex-row justify-center">
                                <GetStartedButton/>
                            </div>
                        </div>


                    </>)
                }
                {
                    page == 'setup_codemaps' && (
                        <SettingUpSection handleSetPage={handleSetPage} initiateLogin={initiateLogin}/>)
                }
            </div>
        </>
    )
}


const HeroSection = () => {
    const texts = [
        <>
            <h2 className="text-xl mb-2" style={{ color: '#430404' }}>A whole new way to look at code!</h2>
            <p style={{ color: '#610202' }}>Code Maps gives your team visibility into your tech ecosystem like never before, allowing your team to onboard, develop, refactor and ship applications faster, unblocking your path to PMF and beyond!</p>
        </>,
        <>
            <h2 className="text-xl mb-2" style={{ color: '#1f4a00' }}>Visualize Your Codebase!</h2>
            <p style={{ color: '#1a5300' }}>Automatically create and maintain visual maps of your entire codebase, highlighting services, directories, and file dependencies.</p>
        </>,
        <>
            <h2 className="text-xl mb-2" style={{ color: '#52006f' }}>Gain Deep Insights!</h2>
            <p style={{ color: '#4a0085' }}>Leverage AI-powered insights to understand code dependencies, hotspots, and the impact of changes, enhancing decision-making and code quality.</p>
        </>,
        <>
            <h2 className="text-xl mb-2" style={{ color: '#99004f' }}>Accelerate Development!</h2>
            <p style={{ color: '#7a008f' }}>Code Maps gives your team visibility into your tech ecosystem like never before, allowing your team to onboard, develop, refactor and ship applications faster, unblocking your path to PMF and beyond!</p>
        </>
    ];


    const [currentText, setCurrentText] = useState(texts[0]);
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % texts.length);
        }, 3000); // Change text every 3 seconds

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        setCurrentText(texts[index]);
    }, [index]);

    return (
        <>
            <h1 className="text-5xl font-bold text-gray-800">Welcome to Code Maps</h1>
            <p className="mt-4 text-lg p-2 min-h-[150px] max-h-[200px] overflow-auto">{currentText}</p>
        </>
    );
};
