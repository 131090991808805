import {useCallback} from "react";

export const Navbar = (props: any) => {
    const initiateLogin = useCallback(() => {
        props.setIsLoggingIn(true);
        props.initiateLogin();
    }, [])
    return (
        <nav className="bg-gray-800 text-white p-4">
            <div className="flex justify-between items-center">
                <div>
                    <a href="#" className="flex items-center">
                        <img src="/118836239.jpeg" alt="Brand Logo" className="h-8 w-auto"/>
                        <h1 className="ml-2">Code Maps</h1>
                    </a>
                </div>
                <div className="flex">
                    <a href="/" className="p-2">Home</a>
                    {/*<a href="/logi" className="p-2">Discover</a>*/}
                    <a href="#" onClick={initiateLogin}
                       className="bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded inline-flex items-center">
                        <img width="22" src="/github-logo.png" alt="GitHub Logo" className="mr-3"/>
                        Sign In
                    </a>
                </div>
            </div>
        </nav>
    );
};
