import ClipLoader from "react-spinners/ClipLoader";

export const OverlayLoader = (props:any) => {
    return (
    <div 
        style={{position : 'fixed',
        top:0,
        left:0,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        width : '100vw',
        height : '100vh',
        background : 'rgb(180 180 180 / 50%)',
        zIndex: 9999}}
    >
        <div>
            <div style={{display:'flex', justifyContent:'center'}}>
                <ClipLoader
                    loading={true}
                    cssOverride={{margin:'0 auto'}}
                />
            </div>
    
            <h4>
                {props.message || "Loading, please wait. This may take up a few minutes ..."}
            </h4>
        </div>

    </div>)
}