interface IState {
  user: any,
  org:any,
  githubtoken?:null|string
}
const initialState:IState = {
    user:null,
    org: null,
    githubtoken: null
  };

  export const userReducer = (state = initialState, action:any) => {
    switch (action.type) {
      case "ADD_USER":
        return {
          ...state,
          user: action.payload,
        };
      case "SELECT_ORG":
        return {
          ...state,
          org: action.payload,
        };
      case "ADD_GITHUB_TOKEN":
        return {
          ...state,
          githubtoken: action.payload,
        };
      case "SIGN_OUT":
        return  initialState;
      default:
        return state;
    }
  };
