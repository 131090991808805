import {checkboxOptionDataSelector} from "../../../store/selectors/sysmap.selectors";
import {useDispatch, useStore} from "react-redux";


type ColorMappingType = {
    [key: string]: string;
};

const colors = [
    {color:"#ffd4f5", text: "Import Declaration | Import Specifier | Namespace Import"},
    {color: "#e1f7c1", text: "Constructor"},
    {color: "#d79df2", text: "Method Declaration | Function Declaration"},
    {color: "#c3eff7", text: "Others"}
];



const colorMapping: ColorMappingType = {
    flowOption: colors[3].color,
    constructors: colors[1].color,
    methods: colors[2].color,
    properties: colors[3].color,
    functions: colors[2].color,
    variables: colors[3].color,
    getAccessors: colors[3].color,
    parameters: colors[3].color,
    enums: colors[3].color,
    imports: colors[0].color,
    typeRefs: colors[3].color,
    namespaceImport: colors[0].color,
    parameter: colors[3].color,
    bindingElement: colors[3].color,
    propertyAssignment: colors[3].color,
    propertySignature: colors[3].color,
    classDeclaration: colors[3].color,
    setAccessor: colors[3].color,
    getAccessor: colors[3].color,
    method: colors[2].color
};

const camelToNormal = (camelCase: string): string => {
    return camelCase
        .replace(/([A-Z])/g, ' $1')
        .replace(/^./, (str) => str.toUpperCase());
}

/**
 * Following exported functional component
 * is used to deal with selection / deselection
 * of checkbox
 */
const CheckboxList = () => {
    const store = useStore();
    const dispatch = useDispatch();
    const checkboxOptions = checkboxOptionDataSelector(store)
    const toggleLinkOnSideOptions = (keyOption: string) => {
        dispatch({type: 'UPDATE_LINK_OPTIONS', payload: {[keyOption]: !checkboxOptions[keyOption]}})
    }
    /** Render Checkbox
     * @param object containing children and props
     * @return JSX element
     */
    const Checkbox = ({children, ...props}: JSX.IntrinsicElements['input']) => (
        <label style={{marginRight: '1em'}}>
            <input style={{marginRight: '0.25em'}} type="checkbox" {...props} />
            {children}
        </label>
    );
    return (
        <div style={{height: "50vh", overflowY: "auto", overflowX: "hidden"}}>
            {
                Object.keys(checkboxOptions).map((key) =>
                    (<Checkbox
                        checked={checkboxOptions[key]}
                        id={key}
                        key={key}
                        onChange={() => toggleLinkOnSideOptions(key)}>
                        {camelToNormal(key)}
                        <span style={{marginLeft: 2, display: "inline-block", width: "15px", height: "15px", background: colorMapping[key]}}></span>
                    </Checkbox>)
                )}
        </div>
    )
}

export default CheckboxList;
