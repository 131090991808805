import {Button} from "antd";
import * as React from "react";


const ErrorPage = () =>{
    return <div className=' absolute top-2/4 left-1/3'>
        <div>
            <h3>Oops! We ran into a problem. Please refresh the page.</h3>
            <Button title='Refresh' onClick={() => window.location.reload()}>
                Reload
            </Button>
        </div>
    </div>
}
export default ErrorPage;
