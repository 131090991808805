import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GithubAuthProvider } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDUxB0F5a0qwSOeRN1wbFA6xudZO1f8Bq8",
    authDomain: "code-maps-3ffa5.firebaseapp.com",
    projectId: "code-maps-3ffa5",
    storageBucket: "code-maps-3ffa5.appspot.com",
    messagingSenderId: "213115595958",
    appId: "1:213115595958:web:c0ff0958a07bdf244e0a17",
    measurementId: "G-F57KKQHJKL",
    signInSuccessUrl: "http://localhost/"
};
  
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const provider = new GithubAuthProvider();

