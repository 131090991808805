import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from "react-router-dom"
import React from 'react';
import './index.css'
// Third party Imports
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import sdk from './instrumentation'; // Import the OpenTelemetry configuration file

(async () => {
  // await sdk.start();
  // console.log('Tracing initialized.');
  // Now that OpenTelemetry is initialized, render your React application
  const root = ReactDOM.createRoot(document.getElementById('root')!);

  root.render(
    <BrowserRouter>
      <ToastContainer />
      <App />
    </BrowserRouter>
  );
})();
