import axios from 'axios';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Loader } from '../../../components/loader';


export const OrgsModal = (props:any) => {
    const [isFetchingSysmap, setIsFetchingSysmap] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const selectOrg = (org:any) => {
        dispatch({type:"SELECT_ORG", payload:org});
    }
    return (
        <>
        <div id="crypto-modal" aria-hidden="true" className=" bg-slate-300 fixed top-0 left-0 right-0 z-50 w-full  h-screen p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">

            <div className="relative left-1/4 top-24 bg-white rounded-lg shadow dark:bg-gray-700 w-1/2">
                <div className="px-6 py-4 border-b rounded-t dark:border-gray-600">
                    <h3 className="text-base font-semibold text-gray-900 lg:text-xl dark:text-white">
                        { "Pick Organization"}
                    </h3>
                </div>
                <div className="p-6">
                    {!isFetchingSysmap &&
                    <>
                        <p className="text-sm font-normal text-gray-500 dark:text-gray-400">We found the following organizations associated with your github account. Please pick one to continue.</p>
                        <ul className="my-4 space-y-3">
                            {props.orgs.map((org:any) =>
                            <li onClick={()=> {selectOrg(org)}} className="cursor-pointer" key={org.id}>
                                <div className=" flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
                                    <img width="8%" src={org.avatar_url}/>
                                    <span className="flex-1 ml-3 whitespace-nowrap">{org.login}</span>
                                </div>
                            </li>
                            )}
                        </ul>
                    </>
                    }
                    {isFetchingSysmap &&
                        <Loader message="Fetching sysmap ..."/>
                    }
                </div>
            </div>
        </div>
     </>
    )
}
