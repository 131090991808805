// React Imports
import React, {useEffect, useState, useRef} from 'react';
import {useStore, useSelector, useDispatch} from 'react-redux';

// Third party Imports
import {FullScreen} from '@chiragrupani/fullscreen-react';
import {ReactDiagram, ReactOverview} from 'gojs-react';
import * as go from 'gojs';

// CSS Imports
import '../../css/Diagram.css';
import '../../css/Search.css';

// utils Imports
import {
    myCallback,
    getLinksForNode,
} from './helper';
import {fetchAppRepoByUUID} from '../../services/github-service';
import {parsedLocation} from './goHelper';
import {OverlayLoader} from '../../components/overlay-loader';
import {getSelectedNodesKeys, linksDataDictSelector} from '../../store/selectors/sysmap.selectors';
import showLinks from "./actions/showLinks";
import createPorts from "./actions/createPorts";
import SidePanel from "./components/SidePanel";
import SearchBar from "./components/SearchBar";
import {borderNode, defaultNode, tableLayout, verticalGroupLayout} from "../../utils/layouts";

export const DiagramWrapper = (props: any) => {
    let diagramRef: React.RefObject<ReactDiagram> = useRef(null);
    const canvasRef: React.RefObject<HTMLCanvasElement> = useRef(null);
    const ref2 = useRef(null);
    const [isLoading, setLoadState] = useState(false)
    const [activeAppRepo, setActiveAppRepo] = useState<any>({})
    const [selectedNode, setSelectedNode] = useState<any>(); // Used to track Selected Node for updating arrows
    const [appState, setAppState] = useState({
        searchKey: "",
        selectValue: "",
        isFullScreen: false,
    });
    const store = useStore();
    const dispatch = useDispatch();
    const handleFullScreenToggle = () => {
        setAppState({...appState, isFullScreen: true})
    }
    let backup: any = [];

    /**
     * Following function is used to filter options based on search
     * returns filtered list
     */
    const initOverview = () => {
        const $ = go.GraphObject.make;
        return $(go.Overview, {contentAlignment: go.Spot.Center});
    }

    /**
     * Following function is used to deal with expansion control
     * @param obj node on which click event has triggered
     */
    const expansionControl = (obj: any) => {
        const data = obj.part.data;
        const node = obj.part as go.Node;
        const diagram = (diagramRef as any).current.getDiagram();
        diagram.startTransaction("updateExpansion");
        node.diagram!.model.commit((m: any) => {
            m.set(data, "isSubGraphExpanded", !data.isSubGraphExpanded);
        }, "clicked");
        diagram.commitTransaction("updateExpansion");
    }
    // Why is this set on link Data Array?
    useEffect(() => {
        if (Object.keys(activeAppRepo).length > 0) {
            Object.keys(activeAppRepo).forEach(element => {
                expansionControl(activeAppRepo[element])
            });
            setLoadState(false)
        }
    }, [props.linkDataArray])

    /**
     * Get the diagram reference and add any desired diagram listeners.
     * Typically, the same function will be used for each listener, with the function using a switch statement to handle the events.
     */
    useEffect(() => {
        if (!diagramRef.current) return;
        const diagram = diagramRef.current.getDiagram();
        if (diagram instanceof go.Diagram) {
            diagram.addDiagramListener('ChangedSelection', handleDiagramEvent);
            diagram.addDiagramListener('InitialLayoutCompleted', handleDiagramEvent);
            diagram.addDiagramListener('LayoutCompleted', handleDiagramEvent);
        }
        return () => {
            if (!diagramRef.current) return;
            const diagram = diagramRef.current.getDiagram();
            if (diagram instanceof go.Diagram) {
                diagram.removeDiagramListener('ChangedSelection', handleDiagramEvent);
                diagram.removeDiagramListener('InitialLayoutCompleted', handleDiagramEvent);
                diagram.removeDiagramListener('LayoutCompleted', handleDiagramEvent);
            }
        }
    }, [])

    /**
     * Update the Links based on FlowOption Changes
     * Dependency Array: linkOptions values
     * */
    const linkOptions = useSelector((state: any) => state.sysmap.linkOptions)
    useEffect(() => {
        if (!diagramRef.current) return;
        const diagram = diagramRef.current.getDiagram();
        if (selectedNode instanceof go.Node || selectedNode instanceof go.Group) {
            getLinksForNode(selectedNode.data.key, props.linkDataArray, linksDataDictSelector(store));
            diagram?.commit((d: any) => {
                showLinks(selectedNode, d, store);
            }, "clear links");
        }
    }, Object.values(linkOptions))


    /** Following function is used to fetch node data array */
    const fetchAppRepoRecord = (uuid: any, appName: any, appRepoName: any) => {
        setLoadState(true)
        fetchAppRepoByUUID(uuid).then((res: any) => {
            if (res) {
                props.setLinkResult(JSON.parse(JSON.stringify({
                    appName: appName,
                    appRepoName: appRepoName,
                    linkDataArray: (res.linkDataArray && res.linkDataArray.length > 0) ? res.linkDataArray : [],
                    nodeDataArray: (res.nodeDataArray && res.nodeDataArray.length > 0) ? res.nodeDataArray : [],
                    linkDataDict: res.linkDataDict || {},
                })))
            } else if (Object.keys(activeAppRepo).length > 0) {
                expansionControl(activeAppRepo[appRepoName])
                setLoadState(false);
            }
        })
    }

    /**Handler for Expansion Control */
    const expansionHandler = async (e: any, obj: any) => {
        const appRepoCopy = activeAppRepo
        if (obj.part.data.isSubGraphExpanded) {
            expansionControl(obj)
            delete appRepoCopy[obj.part.qb.appRepoName]
            props.setLinkResult(appRepoCopy)
            setActiveAppRepo(appRepoCopy)
        } else {
            appRepoCopy[obj.part.qb.appRepoName] = obj;
            setActiveAppRepo(appRepoCopy)
            await fetchAppRepoRecord(obj.part.qb.uuid, obj.part.qb.appName, obj.part.qb.appRepoName)
        }
    };


    /**
     * Diagram initialization method, which is passed to the ReactDiagram component.
     * This method is responsible for making the diagram and initializing the model, any templates,
     * and maybe doing other initialization tasks like customizing tools.
     * The model's data should not be set here, as the ReactDiagram component handles that.
     */
    const initDiagram = (): go.Diagram => {
        const $ = go.GraphObject.make;
        const diagram = $(go.Diagram, {
            layout: $(go.GridLayout, {
                wrappingWidth: Infinity, alignment: go.GridLayout.Position,
                cellSize: new go.Size(1, 1), spacing: new go.Size(4, 4), isInitial: true, isOngoing: true
            }),
            "toolManager.toolTipDuration": 10000,
            "commandHandler.zoomFactor": 1.1,
            "animationManager.isEnabled": false,
            model: $(go.GraphLinksModel, {
                linkKeyProperty: 'key',
                isReadOnly: true
            })
        });

        diagram.linkTemplate = $(go.Link, parsedLocation(),
            {routing: go.Link.Normal, curve: go.Link.JumpGap, opacity: 1},
            $(go.Shape, {fromArrow: "Standard", fill: "red"}),
            $(go.Shape, {toArrow: "Standard"})
        );


        let groupTemplateMap = new go.Map<string, go.Group>();
        groupTemplateMap.add("verticalLayout", verticalGroupLayout($, expansionHandler, focusToSelectedApi));
        groupTemplateMap.add("", tableLayout($));

        const nodeTemplateMap = new go.Map<string, go.Node>();
        nodeTemplateMap.add("", defaultNode($, mapLinksForSelectedNode, canvasRef, store, diagram));
        nodeTemplateMap.add("borderNode", borderNode($));

        diagram.groupTemplateMap = groupTemplateMap;
        diagram.nodeTemplateMap = nodeTemplateMap;

        diagram.model = new go.GraphLinksModel({
            ...props.nodeDataArray,
            linkFromPortIdProperty: "fromPortId",  // required information:
            linkToPortIdProperty: "toPortId",
        });
        return diagram;
    }

    const downloadMap = () => {
        if (diagramRef.current) {
            const diagram = diagramRef.current.getDiagram();
            if (diagram instanceof go.Diagram) {
                const svg = diagram.makeSvg({scale: 1, background: "white"});
                if (svg) {
                    const svgstr = new XMLSerializer().serializeToString(svg);
                    const blob = new Blob([svgstr], {type: "image/svg+xml"});
                    myCallback(blob);
                }
            }
        }
    }

    const handleDiagramEvent = (e: go.DiagramEvent) => {
        const name = e.name;
        const diagram: any = e.diagram;
        switch (name) {
            case 'ChangedSelection': {
                const sel = e.subject.first();
                setSelectedNode(sel)
                if (sel) {
                    if (sel instanceof go.Node || sel instanceof go.Group) {
                        // FIXME: Purpose of this code?
                        diagram.commit((d: any) => {
                            diagram.model.linkDataArray = []
                        }, "clear links");
                        diagram.commit((d: any) => {
                            showLinks(sel, d, store);
                        }, "clear links");
                    }
                }
                break;
            }
            case 'LayoutCompleted': {
                break;
            }
        }
    }

    const itemSelected = (diagram: go.Diagram, node: any) => {
        let label = `Name: ${node.data.text} Kind: ${node.data.kindName}`
        setAppState({...appState, searchKey: node.data.key, selectValue: label});
    }


    // This function is to expand Group for Nodes
    const expandGroupForNode = (node: go.Node, diagram: go.Diagram) => {
        const groupKey = node.data.group;
        const groupNode = diagram.findNodeForKey(groupKey)
        if (groupNode) {
            (groupNode as any).isSubGraphExpanded = true;
            if (groupNode.data.group) {
                expandGroupForNode(groupNode, diagram);
            }
        }
    }

    const mapLinksForSelectedNode = (e: any, node: go.Node) => {
        const selectedNodesKeys = getSelectedNodesKeys(store);
        let selectedNodes = [];
        let nodes: any[];
        if (e.control || e.meta) {
            selectedNodes = selectedNodesKeys.map((id: any) => e.diagram.findNodeForKey(id));
            nodes = [...selectedNodes, node];
        } else {
            nodes = [node];
        }
        const payload = {
            selectedNodesKeys: nodes.map(n => n.key)
        };
        dispatch({type: "UPDATE_SELECTED_NODES", payload: payload})

        e.diagram.model.linkDataArray = []
        node.zOrder = 100 - node.zOrder;
        createPorts(node, null, backup, canvasRef, store);
        showLinks(nodes, e.diagram, store)
    }

    const focusToSelectedApi = async (e: any, node: any) => {
        const selectedEndPoint: any = node.data;
        if (diagramRef.current) {
            const diagram = diagramRef.current.getDiagram();
            if (diagram instanceof go.Diagram) {
                // Wait is added so DB transaction is committed. Timeout value is set using Trial and Error
                if (!diagram.findNodeForKey(selectedEndPoint.key)) {
                    await new Promise(resolve => setTimeout(resolve, 1500));
                }
                const node = diagram.findNodeForKey(selectedEndPoint.key);
                if (node) {
                    expandGroupForNode(node, diagram);
                    const nodeBounds = node.actualBounds;
                    diagram.zoomToRect(nodeBounds);
                    const scaleFactor = 0.9;
                    diagram.scale = Math.min(diagram.viewportBounds.width / nodeBounds.width, diagram.viewportBounds.height / nodeBounds.height) * scaleFactor;
                    diagram.centerRect(nodeBounds);
                    mapLinksForSelectedNode(e, node)
                }
            }
        }
    }

    return (
        <div ref={ref2}>
            {isLoading ? < OverlayLoader
                message="Fetching data ..."
            /> : null}
            <FullScreen
                isFullScreen={appState.isFullScreen}
                onChange={(isFull: boolean) => {
                    setAppState({...appState, isFullScreen: isFull});
                }}
            >
                <div className='w-full'>
                    <canvas ref={canvasRef} style={{display: 'none'}}/>
                    <ReactDiagram
                        ref={diagramRef}
                        divClassName='diagram-component'
                        style={{backgroundColor: '#eee', zIndex: 1}}
                        initDiagram={initDiagram}
                        nodeDataArray={props.nodeDataArray}
                        modelData={props.modelData}
                        onModelChange={props.onModelChange}
                        skipsDiagramUpdate={props.skipsDiagramUpdate}
                    />
                    {(props.showMiniMap) &&
                    (diagramRef.current && diagramRef.current.getDiagram()) ? <ReactOverview
                            initOverview={initOverview}
                            divClassName="overview-component"
                            observedDiagram={(diagramRef as any).current.getDiagram()}
                        />
                        : null
                    }
                </div>
            </FullScreen>
            {props.showSidePanel ?
                <SidePanel downloadMap={downloadMap} handleFullScreenToggle={handleFullScreenToggle}/>
                : <></>
            }
            <div id="block2">
                <SearchBar diagramRef={diagramRef} store={store} itemSelected={itemSelected}
                           setLinkResult={props.setLinkResult} handleSetActiveRepo={setActiveAppRepo}
                           fetchAppRepoRecord={fetchAppRepoRecord} setloadState={setLoadState}/>
            </div>
        </div>);
}
