// Third party Imports
import * as go from 'gojs';

// holder to hold reference to make object
const $ = go.GraphObject.make;

// holder to hold styles of text block
const styles = {
  node: {
    padding: 8,
    alignment: go.Spot.TopLeft
  },
  subgraph: {
    padding: 30,
    alignment: go.Spot.Left,
    background: "#d9dbda"
  }
}

// exporting possible placeholder
export default {
  node: () => $(go.Placeholder, styles.node),
  subgraph: () => $(go.Placeholder, 'Auto', styles.subgraph)
}
