import React, {ChangeEvent} from 'react';
import {useDispatch, useStore} from "react-redux";
import {flowOptionDataSelector} from "../../../store/selectors/sysmap.selectors";


const RadioButtonGroup = () => {
    const onChangeSelected = (newSelectedOption: string) => {
        dispatch({type: 'UPDATE_LINK_OPTIONS', payload: {flowOption: newSelectedOption}});
    }

    // Storing option -> sys-map store
    const store = useStore();
    const dispatch = useDispatch();
    const flowOption = flowOptionDataSelector(store);
    const handleOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newSelectedOption = event.target.value;
        onChangeSelected(newSelectedOption);
    };

    return (
        <div>
            <label>
                <input
                    type="radio"
                    value="Full flow"
                    checked={flowOption === 'Full flow'}
                    onChange={handleOptionChange}
                />
                Full flow
            </label>
            <label>
                <input
                    type="radio"
                    value="1 Layer deep"
                    checked={flowOption === '1 Layer deep'}
                    onChange={handleOptionChange}
                />
                1 Layer deep
            </label>
        </div>
    );
};

export default RadioButtonGroup;
