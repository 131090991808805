import ClipLoader from "react-spinners/ClipLoader";

export const Loader = (props:any) => {
    return (
    <div 
        style={{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        width : '100%',}}
    >
        <div>
            <div style={{display:'flex', justifyContent:'center'}}>
                <ClipLoader
                    loading={true}
                    cssOverride={{margin:'0 auto'}}
                />
            </div>
            <span className="flex-1 ml-3 whitespace-nowrap">{props.message || "Loading..."}</span>
        </div>

    </div>)
}