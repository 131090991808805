import {useSelector} from 'react-redux';
import {RootState} from '../store/store';
import {useNavigate} from "react-router-dom";
import {useDispatch} from 'react-redux';
import {useState} from 'react';
import {SideDrawer} from './sideDrawer';
import '../css/Diagram.css';

export const Navbar = () => {
    const selectedOrg: any = useSelector<RootState>((state) => state?.user?.org);
    const user: any = useSelector<RootState>((state) => state?.user?.user);
    const [showDrawer, setShowDrawer] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const signOut = () => {
        localStorage.removeItem('githubUser');
        localStorage.removeItem('user_name');
        localStorage.removeItem('githubToken');
        dispatch({type: "SIGN_OUT"});
        navigate("/login");
    }
    if (!user)
    {
        return <></>
    }
    return (<>
        <SideDrawer
            showDrawer={showDrawer}
            setShowDrawer={setShowDrawer}
            signOut={signOut}
        />
        <div className="flex justify-between px-8 py-5 bg-slate-700 400 w-full text-white navbar-height border-b-2"
             style={{zIndex: 8887}}>
            {selectedOrg &&
                <div className=" h-full flex items-center">
                    <div className='text-inherit flex items-center'>
                        <img src={selectedOrg?.avatar_url} style={{width: '2rem'}}
                             className=' rounded-3xl border-solid border-2'></img>
                        <strong style={{paddingLeft: '0.5rem'}}>{selectedOrg?.login as string}</strong>
                    </div>
                </div>
            }
            {user &&
                <div className=" h-full flex items-center ml-auto">
                    <div className=' px-2 text-inherit flex align-middle'>
                        <img src={user?.photoURL} style={{width: '2rem'}}
                             className=' border-2 cursor-pointer rounded-3xl'
                             onClick={() => setShowDrawer(!showDrawer)}></img>
                        <strong
                            style={{paddingTop: '0.4rem', paddingLeft: '0.5rem'}}>{user?.displayName as string}</strong>
                    </div>
                    <div
                        className=' px-2 text-inherit flex self-center ml-2 text-xs align-middle underline cursor-pointer'>
                        {/* <span onClick={signOut}>Sign out </span> */}
                    </div>
                </div>
            }


        </div>
    </>)
}
