import {findMultipleOccurences} from "../helper";
import * as go from "gojs";
import showLinks from "./showLinks";
const createPorts = (node: any, active: any, backup: any, canvasRef: any, store: any) => {
  //  node.findObject("CodePanel").visible = true;
    const tb: any = node.findObject("CodeTextBlock");
    if (!tb) return;

    backup.forEach((element: any) => {
        tb.panel.remove(element);
    });
    backup = []
    // alert(tb.panel.elements.count)

    const ctx: any = canvasRef.current?.getContext('2d')
    const ports = node.data.ports;
    node.ensureBounds();
    const m = tb.metrics;
    const maxy = tb.actualBounds.height;
    let y = 0;
    const portsRecordList : any = {}
    const portsRecordListCount : any = {}
    ports.forEach((port:any) => {
        portsRecordList[port.portId] = 0
    });
    for (let i = 0; i < (m && m.arrText && m.arrText.length ? m.arrText.length : []); i++) {
        y += tb.spacingAbove + 10;
        const s = m.arrText[i];
        let p: any = null;
        for (let j = 0; j < (ports && ports.length ? ports.length : []); j++) {
            const port = ports[j];
            //const idx = s.indexOf(port.portId);
            const idxArr = findMultipleOccurences(s,port.portId);
            idxArr.forEach((idx)=>{

                portsRecordListCount[port.portId] = !portsRecordListCount[port.portId] ? 1 : (portsRecordListCount[port.portId] + 1)

                // The if statement below ensures that a port is not created for a substring from a line of code
                // for example, it ensures that for a given port "get", port is not created where the word get
                // is a substring, e:d in a token like getLocations. This is why we check the next character after get to ensure
                // if this occurance of "get" is a valid port
                const nextChar = s[idx + port.portId.length];
                if (/^[A-Za-z]$/.test(nextChar)) {
                    return
                }

                let p = node.findPort(port.portId);

                let portIdToAssign = port.portId;

                if(portsRecordList[portIdToAssign] !== 0){
                    portIdToAssign=portIdToAssign+`${portsRecordList[portIdToAssign]}`;
                }
                p = new go.Shape({
                    stroke: active == portIdToAssign ? "blue" : "red",
                    strokeWidth: active == portIdToAssign ? 0.9 : 0.5,
                    fill: 'transparent',
                    name: portIdToAssign + portsRecordListCount[port.portId],
                    portId: port.portId,
                    click: (e: any, obj: any) => {
                        if (portIdToAssign == active) {
                            createPorts(node, null, backup, canvasRef, store)
                        } else {
                            setTimeout(() => {
                              showLinks(node, e.diagram, store)
                            }, 50);
                            createPorts(node, portIdToAssign, backup, canvasRef, store)
                        }


                        //  (obj as any).Lc = "blue"; alert("in")
                    },
                });
                tb.panel.add(p);
                backup.push(p);
                portsRecordList[portIdToAssign] = portsRecordList[portIdToAssign] + 1


                ctx.font = tb.font;
                p.width = ctx.measureText(port.portId).width;
                p.height = Math.max(0, m.fontHeight);
                let x = ctx.measureText(s.substring(0, idx)).width;

                let left = 0;
                if (tb.textAlign === "center") left = (tb.actualBounds.width - m.arrSize[i]) / 2;
                else if (tb.textAlign === "right") left = (tb.actualBounds.width - m.arrSize[i]);
                p.alignment = new go.Spot(0,0,left + x,((i)*m.fontHeight));
                p.alignmentFocus = go.Spot.TopLeft;
            })
            y += m.fontHeight + tb.spacingBelow;
        }
    }
};

export default createPorts;
