import React, {useCallback} from 'react';
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {demoGhToken, demoUserData} from "../../../utils/user_flows";

const GetStartedButton = () => {


    const dispatch = useDispatch();
    const navigate = useNavigate();
    /**
     * Function is used to set logged in user in local storage
     * with expiry defined
     * @param key identificaution for logged in user
     * @param value record to be saved
     * @param ttl time to live in localStorage
     * @return void
     */
    const setLoggedInUserWithExpiry = useCallback((key: string, value: any, ttl: number) => {
        const now = new Date()
        const item = {
            value: value,
            expiry: now.getTime() + ttl,
        }

        localStorage.setItem(key, JSON.stringify(item))
    }, [])

    // This function sets a token that is used for demo purpose.
    const setTrialToken = () => {
        dispatch({
            type: 'ADD_USER', payload: demoUserData
        });
        setLoggedInUserWithExpiry('githubUser', demoUserData, 86400000)
        dispatch({type: 'ADD_GITHUB_TOKEN', payload: demoGhToken});
        setLoggedInUserWithExpiry('githubToken', demoGhToken, 86400000)
        navigate("/canvas?mapID=demo-org-codemaps");

    }
    return (
        <div className="relative w-full h-full border border-gray-500">
            <img
                src={'/screenshot-hor.png'}
                alt="Blurred Background"
                className="w-full filter"
            />
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
                <button
                    className="flex items-center p-4 text-lg font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white"
                    onClick={setTrialToken}
                >
                    Click Here to Start with Demo
                </button>
            </div>
        </div>
    );
};

export default GetStartedButton;
