const SettingUpSection = (props: any) => {
    return (
        <div className="container mx-auto px-4 py-8">
            <div className="text-left mb-6">
                <button onClick={() => {props.handleSetPage('getting_started')}} className="text-gray-600 hover:text-gray-300 hover:bg-gray-150 text-2xl">
                    ←
                </button>
            </div>
            <h1 className="text-3xl font-bold text-center mb-6">Setting up Code Maps is Easy</h1>

            <div className="mb-6 text-center">
                <p className="text-lg mb-4">Install your CodeMaps Bot on your repository or your organisation</p>
                <a href={"https://github.com/apps/codemaps-bot"} className="bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded">
                    CodeMaps Bot on Github Marketplace
                </a>
            </div>

            <div className="mb-6 text-center">
                <p className="text-lg mb-4">Sign in using your Github Id</p>
                <a href="#" target="_blank" rel="noopener noreferrer" className="inline-block" onClick={props.initiateLogin}>
                    <div className="flex items-center justify-center p-4 text-lg font-bold rounded-lg group hover:shadow bg-gray-700 hover:bg-gray-600 text-white">
                        <img width="30" src="/github-logo.png" alt="GitHub Logo" className="mr-3"/>
                        Sign in With Github
                    </div>
                </a>
            </div>
        </div>
    );
};



export default SettingUpSection;
