import axios from "axios";
import {store} from "../store/store";
import {Octokit} from "octokit";
import {MetaData} from "../store/reducers/sysmap";
import {demoGhToken, demoMetaData} from "../utils/user_flows";


export const getGithubUser = async () => {
    const token = store.getState().user.githubtoken;
    const instance = axios.create({
        baseURL: 'https://api.github.com',
        headers: {'Authorization': `Bearer ${token}`}
    });
    const res = await instance.get('/user');
    // if token is valid
    if (res.status === 200) {
        return res.data;
    } else if (res.status >= 400 && res.status < 500) {
        localStorage.clear();
        return {
            status: res.status,
            message: "Github token is invalid"
        }
    }
}

export const getUserOrgs = async () => {
    const token = store.getState().user.githubtoken;
    const instance = axios.create({
        baseURL: 'https://api.github.com',
        headers: {'Authorization': `Bearer ${token}`}
    });
    const res = await instance.get('/user/orgs');
    return res.data;
}

export const getOrgsRepos = async (org: string) => {
    const token = store.getState().user.githubtoken;
    const instance = axios.create({
        baseURL: 'https://api.github.com',
        headers: {'Authorization': `Bearer ${token}`}
    });
    if (!org) {
        return []
    }
    const res = await instance.get(`/orgs/${org}/repos`);
    return res.data;
}

export const getRepos = async (org: string) => {
    const token = store.getState().user.githubtoken;
    const octokit = new Octokit({auth: token});
    const res = await octokit.request("GET /orgs/{org}/repos", {
        org: org, // Replace with your organization name
    })
    return res.data;
}

export const getRepoDetails = async (name: string, owner: string) => {
    const token = store.getState().user.githubtoken;
    try {
        const octokit = new Octokit({auth: token});

        const response = await octokit.request("GET /repos/{owner}/{repo}/commits", {
            owner: owner,
            repo: name,
        });
        return response.data

    } catch (error) {
        console.error(error);
    }
};

export const saveRepo = (data: any) => {
    return axios.post('https://backend-codemaps-a4yqkukaqa-uc.a.run.app/repos/new', data);
}

export const saveOrg = (data: any) => {
    return axios.post('https://backend-codemaps-a4yqkukaqa-uc.a.run.app/orgs/new', data);
}

/**
 * Following function is used to fetch branches list
 * @param repo name of repository
 * @param owner name of owner
 * @param page page number
 * @return promise
 */
export const getBranchList = (repo: string, owner: string, page: number) => {
    const token = store.getState().user.githubtoken;
    const octokit = new Octokit({
        auth: token
    });

    return octokit.request('GET /repos/{owner}/{repo}/branches', {
        owner, repo, per_page: 10, page,
        headers: {
            'X-GitHub-Api-Version': '2022-11-28'
        }
    })
};

/**
 * Following function is used to fetch commits list
 * @param repo name of repository
 * @param owner name of owner
 * @param page page number
 * @return promise
 */
export const getRepoCommits = (repo: string, owner: string, page: number) => {
    const token = store.getState().user.githubtoken;
    const octokit = new Octokit({auth: token});

    return octokit.request("GET /repos/{owner}/{repo}/commits", {
        owner, repo, per_page: 5, page,
        headers: {
            'X-GitHub-Api-Version': '2022-11-28'
        }
    });
};

/**
 * Following function is used to fetch issues list
 * @param repo name of repository
 * @param owner name of owner
 * @param branch name of branch
 * @return promise
 */
export const getRepoIssues = (repo: string, owner: string, page: number) => {
    const token = store.getState().user.githubtoken;
    const octokit = new Octokit({auth: token});

    return octokit.request("GET /repos/{owner}/{repo}/issues", {
        owner, repo, per_page: 5, page,
        headers: {
            'X-GitHub-Api-Version': '2022-11-28'
        }
    });
};

/**
 * Following function is used to fetch issues list
 * @param repo name of repository
 * @param owner name of owner
 * @param branch name of branch
 * @return promise
 */
export const getCommitsByPR = (repo: string, owner: string, pull_number: number, page: number) => {
    const token = store.getState().user.githubtoken;
    const octokit = new Octokit({auth: token});

    return octokit.request("GET /repos/{owner}/{repo}/pulls/{pull_number}/commits", {
        owner, repo, pull_number, per_page: 5, page,
        headers: {
            'X-GitHub-Api-Version': '2022-11-28'
        }
    });
};

/**
 * Following function is used to fetch issues list
 * @param repo name of repository
 * @param owner name of owner
 * @param branch name of branch
 * @return promise
 */
export const getPRs = (repo: string, owner: string, page: number) => {
    const token = store.getState().user.githubtoken;
    const octokit = new Octokit({auth: token});

    return octokit.request("GET /repos/{owner}/{repo}/pulls", {
        owner, repo, per_page: 5, page,
        headers: {
            'X-GitHub-Api-Version': '2022-11-28'
        }
    });
};

/**
 * Following function is used to fetch issues list
 * @param repo name of repository
 * @param owner name of owner
 * @param branch name of branch
 * @return promise
 */
export const getClosedPRs = (repo: string, owner: string, page: number) => {
    const token = store.getState().user.githubtoken;
    const octokit = new Octokit({auth: token});

    return octokit.request("GET /repos/{owner}/{repo}/pulls", {
        owner, repo, state: 'closed', per_page: 5, page,
        headers: {
            'X-GitHub-Api-Version': '2022-11-28'
        }
    });
};

/**
 * Following function is used to fetch issues list
 * @param repo name of repository
 * @param owner name of owner
 * @param branch name of branch
 * @return promise
 */
export const getPRCommits = (repo: string, owner: string, pull_number: string, page: number) => {
    const token = store.getState().user.githubtoken;
    const octokit = new Octokit({auth: token});

    return octokit.request("GET /repos/{owner}/{repo}/pulls/{pull_number}/commits", {
        owner, repo, pull_number: Number(pull_number), per_page: 5, page,
        headers: {
            'X-GitHub-Api-Version': '2022-11-28'
        }
    });
};


/**
 * Following function is used to fetch issues list
 * @param repo name of repository
 * @param owner name of owner
 * @param branch name of branch
 * @return promise
 */
export const getPRCommitsFiles = (repo: string, owner: string, pull_number: number, page: number) => {
    const token = store.getState().user.githubtoken;
    const octokit = new Octokit({auth: token});

    return octokit.request("GET /repos/{owner}/{repo}/pulls/{pull_number}/files", {
        owner, repo, pull_number, per_page: 5, page,
        headers: {
            'X-GitHub-Api-Version': '2022-11-28'
        }
    });
};


export const getPRBranchCommits = (repo: string, owner: string, branch: string, page: number) => {
    const token = store.getState().user.githubtoken;
    const octokit = new Octokit({auth: token});

    return octokit.request("GET /repos/{owner}/{repo}/commits", {
        owner, repo, sha: branch, per_page: 10, page,
        headers: {
            'X-GitHub-Api-Version': '2022-11-28'
        }
    });
};

/**
 * Following function is used to fetch issues list
 * @param repo name of repository
 * @param owner name of owner
 * @param branch name of branch
 * @return promise
 */
export const getallPRs = (repo: string, owner: string, base: string, page: number) => {
    const token = store.getState().user.githubtoken;
    const octokit = new Octokit({auth: token});

    const login = ((store.getState() as any).user.org && (store.getState() as any).user.org.login) ? (store.getState() as any).user.org.login : ''

    return octokit.request("GET /repos/{owner}/{repo}/pulls", {
        owner, repo, state: 'all', head: login + ':' + base, per_page: 5, page,
        headers: {
            'X-GitHub-Api-Version': '2022-11-28'
        }
    });
};

export const getOneCommit = (repo: string, owner: string, commit_sha: string) => {
    const token = store.getState().user.githubtoken;
    const octokit = new Octokit({auth: token});

    const login = ((store.getState() as any).user.org && (store.getState() as any).user.org.login) ? (store.getState() as any).user.org.login : ''

    return octokit.request("GET /repos/{owner}/{repo}/git/commits/{commit_sha}", {
        owner, repo, commit_sha,
        headers: {
            'X-GitHub-Api-Version': '2022-11-28'
        }
    });
};


export const getSysmap = async (org: string, filters: any) => {
    let token = store.getState().user.githubtoken || '';
        const rawData = await axios.post(`https://backend-codemaps-a4yqkukaqa-uc.a.run.app/sysmaps/${org}`, {},
        {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
    );
    return rawData.data;
}

export const fetchRepoRecords = async (reqBody: any) => {
    const token = store.getState().user.githubtoken;
    const rawData = await axios.post('https://backend-codemaps-a4yqkukaqa-uc.a.run.app/codemaps/getForCommit', reqBody, {
        headers: {'Authorization': `Bearer ${token}`}
    });
    return rawData.data;
};

// FIXME: Remove unnecessary params
export const fetchAppRepoByUUID = async (uuid: any, metaData= demoMetaData) => {
    const state = store.getState();
    let appMeta = metaData.find((item: MetaData) => item.uuid === uuid)
    let token = state.user.githubtoken;
    if (token !== demoGhToken) { // This means that the user is not a dummy user
        appMeta = state.sysmap.metaData.find((item: MetaData) => item.uuid === uuid)
    }
    if (appMeta?.appRepoName && !!state.sysmap.nodesDataList[appMeta.appRepoName]) {
        return null;
    }

    const response = await axios.get(`https://backend-codemaps-a4yqkukaqa-uc.a.run.app/codemaps/${uuid}`, {
        headers: {'Authorization': `Bearer ${token}`}
    });

    return response.data;
}
