const initialState = {
    selectedOrg:null
  };
  
  export const githubReducer = (state = initialState, action:any) => {
    switch (action.type) {
      case "TO_BE_REPLACED":
        return {
          ...state,
          selectedOrg: action.payload,
        };
      default:
        return state;
    }
  };