import {
    checkboxOptionDataSelector,
    flowOptionDataSelector,
    linksDataDictSelector
} from "../../../store/selectors/sysmap.selectors";
import {isLinkOfRequiredType, setFullOpacity} from "../helper";
import {Store} from "@reduxjs/toolkit";
import {astNodeMapper} from "../constants";
import {setAllNodesTransparent, setAllNodesVisible, setNodeVisible} from "../../../utils/diagram_flows";
import go from "gojs";


const showLinks = (nodes: any | any[], diagram: any, store: Store) => {
        const nodesArray = Array.isArray(nodes) ? nodes : [nodes];
        /** If Show Links is Triggered by clicking on a Group, should make all nodes visible */
        setAllNodesTransparent(diagram);  // Make All Nodes Transparent
        // set selected nodes opacity as 1
        nodesArray.forEach((node: go.Node) => {
            if (node?.data?.isGroup) {
                setAllNodesVisible(diagram);
            } else {
                node.opacity = 1
            }
        })

        // Helper function to process a single node
        const processNode = (node: any) => {

            // Get Links Data and Flow Option from Redux
            let linksData = linksDataDictSelector(store);
            let flowOption = flowOptionDataSelector(store);
            let checkedOptions = checkboxOptionDataSelector(store);

            // Get all Unchecked items
            const uncheckedOptions = Object.keys(checkedOptions).reduce((acc: string[], key: string) => {
                if (checkedOptions[key] === false) {
                    const mapperKey = key as keyof typeof astNodeMapper;
                    acc.push(astNodeMapper[mapperKey]);
                }
                return acc;
            }, []);

            let nodeData = node.data;
            if (linksData[nodeData.key]) {
                let visited = {} as any;
                let nodesToVisit = [] as any;

                // Process incoming links
                if (linksData[nodeData.key] && linksData[nodeData.key].incomingLinks) {
                    linksData[nodeData.key].incomingLinks.forEach((link: any) => {
                        if (uncheckedOptions && !uncheckedOptions.includes(link.toKindName)) {
                            if (flowOption === 'Full flow') {
                                nodesToVisit.push(link.fromPortId);
                                nodesToVisit.push(link.from);
                            }
                            finalLinksDataArray.push(link);
                        }
                    });
                }

                while (nodesToVisit.length) {
                    const currentNodeKey = nodesToVisit.pop();
                    visited[currentNodeKey] = true;
                    if (linksData[currentNodeKey] && linksData[currentNodeKey].incomingLinks) {
                        linksData[currentNodeKey].incomingLinks.forEach((link: any) => {
                            if (!visited[link.fromPortId] && isLinkOfRequiredType(link.toKindName)) {
                                nodesToVisit.push(link.fromPortId);
                                finalLinksDataArray.push(link);
                            }
                            if (!visited[link.from] && isLinkOfRequiredType(link.toKindName)) {
                                nodesToVisit.push(link.from);
                                finalLinksDataArray.push(link);
                            }
                        });
                    }
                }

                setNodeVisible(diagram, visited);

                // Process outgoing links
                nodesToVisit = [];
                visited = {};
                if (linksData[nodeData.key] && linksData[nodeData.key].outgoingLinks) {
                    linksData[nodeData.key].outgoingLinks.forEach((link: any) => {
                        if (uncheckedOptions && !uncheckedOptions.includes(link.toKindName)) {
                            if (flowOption === 'Full flow') {
                                nodesToVisit.push(link.toPortId);
                                nodesToVisit.push(link.to);
                            }
                            finalLinksDataArray.push(link);
                        }
                    });
                }

                while (nodesToVisit.length) {
                    const currentNodeKey = nodesToVisit.pop();
                    visited[currentNodeKey] = true;
                    if (linksData[currentNodeKey] && linksData[currentNodeKey].outgoingLinks) {
                        linksData[currentNodeKey].outgoingLinks.forEach((link: any) => {
                            if (!visited[link.toPortId] && isLinkOfRequiredType(link.toKindName)) {
                                nodesToVisit.push(link.toPortId);
                                finalLinksDataArray.push(link);
                            }
                            if (!visited[link.to] && isLinkOfRequiredType(link.toKindName)) {
                                nodesToVisit.push(link.to);
                                finalLinksDataArray.push(link);
                            }
                        });
                    }
                }

                setNodeVisible(diagram, visited);

            }
        };

// Start a transaction
        diagram.startTransaction("reveal links");

        const finalLinksDataArray = [] as any;
        nodesArray.forEach(node => {
            processNode(node);
        });
        diagram.model.linkDataArray = [...finalLinksDataArray];

        diagram.commitTransaction("reveal links");
    }
;

export default showLinks;
