// Third party Imports
import * as go from 'gojs';
// Utils Imports
import {
    groupLevelTextBinding,
    defaultTextBind,
    kindNameTextBinding,
    tooltipTextBind
} from '../helper';

// holder to hold reference to make object
const $ = go.GraphObject.make;

// holder to hold styles of text block
const styles = {
    application: {
        font: 'Bold 10pt Sans-Serif',
        margin: new go.Margin(1, 2)
    },
    group: {
        margin: new go.Margin(1, 2),
        editable: false,
        stroke: '#616161'
    },
    header: {
        font: 'bold 16pt sans-serif',
        margin: new go.Margin(0, 4),
        maxSize: new go.Size(350, 50)
    },
    tooltip: {
        margin: 4,
    },
    defaultgroup: {
        font: 'bold 12pt sans-serif',
        stroke: '#424242'
    },
    node: {
        font: '10pt sans-serif',
        stroke: "#424242",
        name: 'CodeTextBlock'
    },
    option: {
        font: '12pt FontAwesome',
        stroke: "#424242",
        name: 'option'
    },
}

/**
 * Following function is used to create binding
 * based on passed type, key and callback
 * @param type binding type like text, background etc
 * @param key property to be extracted for display
 * @param callback function to be called if to update strings
 * @return go binding
 */
const bindText = (type: string, key: string, callback: any) => (new go.Binding(type, key, callback))

function onTextBlockClick(e: any, obj: any) {
    const group = obj.part;
    if (group instanceof go.Group) {
        group.isSubGraphExpanded = !group.isSubGraphExpanded;
    }
}

// holder to hold text to be dispalyed
const textDisplay = {
    application: bindText('text', '', groupLevelTextBinding),
    group: bindText('text', 'name', defaultTextBind),
    apiGroup: bindText('text', 'apiName', defaultTextBind),
    header: bindText('text', '', kindNameTextBinding),
    tooltip: bindText('text', '', tooltipTextBind),
    defaultgroup: bindText('text', '', kindNameTextBinding),
    node: bindText('text', 'text', defaultTextBind),
    option: bindText('text', 'text', () => "\uf013"),
}

// exporting possible textblocks
export default {
    application: () => $(go.TextBlock, styles.application, textDisplay.application),
    group: () => $(go.TextBlock, styles.group, textDisplay.group),
    apiGroup: () => $(go.TextBlock, styles.group, textDisplay.apiGroup),
    header: () => $(go.TextBlock, styles.header, textDisplay.header, {click: onTextBlockClick}),
    tooltip: () => $(go.TextBlock, styles.tooltip, textDisplay.tooltip),
    defaultgroup: () => $(go.TextBlock, styles.defaultgroup, textDisplay.defaultgroup),
    node: () => $(go.TextBlock, styles.node, textDisplay.node),
    option: () => $(go.TextBlock, styles.option, textDisplay.option),
}
