import RadioButtonGroup from "./RadioButtonGroup";
import CheckboxList from "./Checkboxes";
import React, {useState} from "react";


interface SidePanelProps {
    downloadMap: any,
    handleFullScreenToggle: any
}

const SidePanel: React.FC<SidePanelProps> = ({downloadMap, handleFullScreenToggle}) => {
    const [show, toggleVisibility] = useState(false);
    return <>
        <div id="side-panel-div" className={`side-panel-div ${show ? '' : 'close-pan'}`}>
            <div id="side-panel" onClick={() => {
                toggleVisibility(!show)
            }}>
                <div id="title">
                    <div className="flex items-center">
                        <div>Controls</div>
                        {show ? (
                            <div
                                className="ml-2 w-0 h-0 border-l-4 border-r-4 border-b-4 border-transparent border-b-black"
                                style={{lineHeight: '0'}} ></div>) : (<div
                                className="ml-2 w-0 h-0 border-l-4 border-r-4 border-t-4 border-transparent border-t-black"
                                style={{lineHeight: '0'}}></div>)}
                    </div>
                </div>
                <div id="sub-title">Flow</div>
                <div onClick={(e) => e.stopPropagation()}>
                    <RadioButtonGroup/>
                </div>
                <div id="sub-title">Filters</div>
                <div onClick={(e) => e.stopPropagation()}>
                    <CheckboxList/>
                </div>
            </div>
            <button onClick={handleFullScreenToggle}>Go Fullscreen</button>
            <button onClick={downloadMap}>Download</button>
        </div>
    </>
}


export default SidePanel;
