/**This Variable is used to Map Sysmaps Filters with item kind definition from backend
 * TODO: Connect Filters ex enum is filtered out (should children be filtered out)
 * */
export const astNodeMapper = {
    variables: "VariableDeclaration",
    functions: "FunctionDeclaration",
    properties: "PropertyDeclaration",
    methods: "MethodDeclaration",
    constructors: "ConstructorDeclaration",
    getAccessors: "GetAccessorDeclaration",
    parameters: "ParameterDeclaration",
    enums: "EnumDeclaration",
    imports: "ImportDeclaration",
    typeRefs: "TypeReferenceNode",
    namespaceImport: "NamespaceImport",
    parameter: "Parameter",
    bindingElement: "BindingElement",
    propertyAssignment: "PropertyAssignment",
    propertySignature: "PropertySignature",
    classDeclaration: "ClassDeclaration",
    setAccessor: "SetAccessor",
    getAccessor: "GetAccessor",
    method: "MethodDeclaration",
    application: "Application",
    sourceFile: "SourceFile"
};
