import {astNodeMapper} from "../pages/canvas/constants";
import {fetchAppRepoByUUID} from "../services/github-service";
import go from "gojs";


// Filter Node Data Array According to Selected Items
export const filterNodeDataArray = (nodeDataArray: any, checkedOptions: any) => {
    const unCheckedItems = Object.keys(checkedOptions).reduce((acc: string[], key: string) => {
        if (checkedOptions[key] === false) {
            const mapperKey = key as keyof typeof astNodeMapper;
            acc.push(astNodeMapper[mapperKey]);
        }
        return acc;
    }, []);
    let filteredNodeArray = JSON.parse(JSON.stringify(nodeDataArray));
    return filteredNodeArray.filter((item: any) => !unCheckedItems.includes(item.kindName));
}


export const setAllNodesTransparent = (diagram: any) => {
    diagram.nodes.each((node: any) => {
        if (!node.data.isGroup) {
            node.opacity = 0.3
        }
    });
}

export const setAllNodesVisible = (diagram: go.Diagram) => {
    diagram.nodes.each((node: go.Node) => {
        if (!node.data.isGroup) {
            node.opacity = 1
        }
    });
}

export const setNodeVisible = (diagram: any, visited: any) => {
    /**Make Visited Nodes Visible*/
    diagram.nodes.each((node: any) => {
        if (visited[node.data.key]) {
            node.opacity = 1
        }
    })

}

function isAncestor(possibleAncestor: go.Node, node: go.Node) {
    while (node.containingGroup) {
        if (node.containingGroup === possibleAncestor) {
            return true;
        }
        node = node.containingGroup;
    }
    return false;
}

// shouldCheckNode: Function Checks if node A is ancestor of node B and Vice Verse
export function shouldCheckNode(checkingNode: go.Node, node: go.Node) {
    if (checkingNode.actualBounds.intersectsRect(node.actualBounds)) {
        // Condition 2: Ensure nodes are not the same
        if (checkingNode === node) {
            return false;
        }
        // Condition 1: Ensure neither node is an ancestor of the other
        else if (isAncestor(checkingNode, node) || isAncestor(node, checkingNode)) {
            return false;
        }

        return true
    }
    return false;
}
