const click = (codePanel: any, copyButton: any) => {
  // Making sure its not visible before
  // Following steps will be called only when its double clicked
  if (!codePanel.visible && !copyButton.visible) {
    codePanel.visible = true;
    copyButton.visible = true;

    return true
  }

  return false
};

export default click;
