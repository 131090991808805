import AsyncSelect from "react-select/async";
import React, {RefObject, useEffect, useState} from "react";
import axios from "axios";
import {ReactDiagram} from "gojs-react";
import {Store} from "@reduxjs/toolkit";
import {appNamesSelector} from "../../../store/selectors/sysmap.selectors";
import go from "gojs";

const SEARCH_BASEURL = "https://search-app-a4yqkukaqa-uc.a.run.app"
//Sample: codemap: 17b12847-bf9e-4e7f-bdbe-d7081825bd4d backend_codemaps main backend_codemaps 648fa5b4d93893060fdd92f497d6efcbb4218d35
interface SearchBarProps {
    diagramRef: React.RefObject<ReactDiagram>,
    store: Store,
    setLinkResult: any,
    itemSelected: any,
    handleSetActiveRepo: any,
    fetchAppRepoRecord: any,
    setloadState: any,
}

/** TODO:
 * 1. Add min char filter: len > 3 for search to start
 * 2. Add Limit to number of rows returned (preferably backend)
 * 3. Implement Search API.
 * 4. On response received zoom to object
 * * */

const filterOptions = async (inputValue: string, appNames: any) => {
    if (inputValue && inputValue.length < 3) {
        return []
    }
    let payload = appNames;
    payload.node_text = inputValue;
    // const res = await axios.post(SEARCH_BASEURL + '/nodes/search-nodes', payload)
    return [{
        'label': 'index.ts-backend_codemaps-backend_codemapsb',
        'node_key': 'index.ts-backend_codemaps-backend_codemaps',
        'commit': '648fa5b4d93893060fdd92f497d6efcbb4218d35',
        'app': 'codemapsrunner',
        'uuid': 'ca5f571a-4018-460c-8844-6884742d7a90',
        'repo': 'codemapsrunner-codemapsrunner'
    }]
    // return res.data;
};

const loadOptions = (inputValue: string, appNames: any, callback: (options: any) => void) => {
    filterOptions(inputValue, appNames).then((options) => {
        callback(options)
    });
};


const optionSelected = (selectedOption: any, diagramRef: RefObject<ReactDiagram>, itemSelected: any, setSelectedOption: any) => {
    setSelectedOption(selectedOption)
    // /**Diagram*/
    // if (diagramRef.current) {
    //     const diagram = diagramRef.current.getDiagram();
    //     if (diagram instanceof go.Diagram) {
    //         const node = diagram.findNodeForKey(selectedOption.node_key);
    //         console.log('Node Found')
    //         console.log(node)
    //         if (node) {
    //             const appNode: any = diagram.findNodeForKey(node.data.appName);
    //             if (appNode) {
    //                 diagram.commit((d: any) => {
    //                     diagram.model.setDataProperty(appNode.data, "isSubGraphExpanded", true);
    //                     diagram.select(node);
    //                 });
    //                 diagram.select(node);
    //                 diagram.centerRect(node.actualBounds);
    //                 diagram.zoomToRect(node.actualBounds);
    //                 diagram.commandHandler.decreaseZoom(0.2);
    //                 itemSelected(diagram, node);
    //             }
    //         }
    //     }
    // }
};

const SearchBar: React.FC<SearchBarProps> = (props) => {
    const [selectedOption, setSelectedOption] = useState({uuid: "", node_key: "", app: "", repo: ""});
    useEffect(() => {
        if (selectedOption.uuid){
            props.setloadState(true)
            props.fetchAppRepoRecord(selectedOption.uuid, selectedOption.app, selectedOption.repo)
        }
    }, [selectedOption])


    let appNames = appNamesSelector(props.store);
    return (<AsyncSelect
            cacheOptions={true}
            isSearchable
            placeholder="Search"
            loadOptions={(inputValue, callback) => loadOptions(inputValue, appNames, callback)}
            onChange={(selectedOption) => {
                optionSelected(selectedOption, props.diagramRef, props.itemSelected, setSelectedOption)
            }}
        />
    )

}

export default SearchBar;
