// React Imports
import { useEffect, useCallback } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom"
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from "./store/store";

// Components Imports
import { Navbar } from "./components/navbar";

// Pages Imports
import { Canvas } from './pages/canvas/Canvas'
import { Home } from "./pages/home/home"
import { Landing } from "./pages/login/Landing"
import { CreateMap } from "./pages/create-map/create-map";

// CSS Imports
import './css/App.css';
import { Repos } from './pages/repos/repos';

const App = () => {
  const queryClient = new QueryClient();
  const location = useLocation();
  // function to manage router navigation
  const navigate = useNavigate();
  // holder to hold route values
  const routes = [{
     path: "/",
     element: <Home />
   }, {
     path: "/canvas",
     element: <Canvas />
   }, {
     path: "/create-map",
     element: <CreateMap />
   }, {
     path: "/login",
     element: <Landing />
   }, {
    path: "/repos",
    element: <Repos />
  }, {
     path: "*",
     element: <Home />
   }]

  useEffect(() => {
    // Following if block is used to save redirect url if
    // user is on non login pages
    const isValidPath = routes.filter(route => route.path != '/login' && route.path == location.pathname).length > 0
    // TODO: Discuss UseCase.
    // if (location.pathname != "/login" && isValidPath) {
    //   setLocalStorageWithoutExpiry('redirectUrl', {
    //     value: {
    //       pathname: location.pathname,
    //       search: location.search
    //     }
    //   })
    // }

    // Following if block is used to redirect to login
    // when user is on non login page and user token expired
    const token = getWithExpiry('githubToken')
    const usr = getWithExpiry('githubUser')
    if ((!usr || !token) && location.pathname != "/login") {
      redirectToLogin()
    }

    // Following if block is used to redirect to home
    // when user is on non login page and user token not expired and url incorrect
    if (usr && token && location.pathname != "/login" && !isValidPath) {
      redirectToHome()
    }
  }, [location])

  /**
   * Following function is used to deal with redirection
   * to login page
   * @return void
   */
  const redirectToLogin = useCallback(() => {
    navigate('/login');
  }, [])

  /**
   * Following function is used to deal with redirection
   * to home page
   * @return void
   */
  const redirectToHome = useCallback(() => {
    navigate('/home');
  }, [])

  /**
   * Following function is used to get if localStorage
   * is present or empty against provided key
   * @return value/null
   */
  const getWithExpiry = useCallback((key: string) => {
    const itemStr = localStorage.getItem(key)
  	if (!itemStr || itemStr.toString().length < 1) {
		  return null
	  }

    try {
      const item = JSON.parse(itemStr)
  	  const now = new Date()
  	  // compare the expiry time of the item with the current time
  	  if (now.getTime() > item.expiry) {
  		  // If the item is expired, delete the item from storage
  		  // and return null
  		  localStorage.removeItem(key)
  		  return null
  	  }

      return item.value
    } catch(e) {
      return null
    }
  }, [])

  /**
   * Following function is used to set localStorage
   * without expiry
   * @return null/true
   */
  const setLocalStorageWithoutExpiry = useCallback((key: string, value: any) => {
    // Following if check returns null if any of input is missing
    if (key.trim().length < 1 && value) {
      return null
    }

    // set values
    localStorage.setItem(key.trim(), JSON.stringify(value))
    return true
  }, [])

  /**
   * Following function is used to get localStorage
   * without expiry
   * @return null/value
   */
  const getLocalStorageWithoutExpiry = (key: string) => {
    // Following if check returns null if any of input is missing
    if (key.trim().length < 1) {
      return null
    }

    const itemStr = localStorage.getItem(key.trim())
    // returns null if item missing
  	if (!itemStr || itemStr.toString().length < 1) {
		  return null
	  }

    try {
      const item = JSON.parse(itemStr)

      return item.value
    } catch(e: any) {
      return null
    }
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <Navbar/>
          <Routes>
            {routes.map(route => <Route key={route.path} path={route.path} element={route.element} /> )}
          </Routes>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
