import go from "gojs";

export interface LinkOptions {
    flowOption: string,
    constructors: boolean,
    methods: boolean,
    properties: boolean,
    functions: boolean,
    variables: boolean,
    getAccessors: boolean,
    parameters: boolean,
    enums: boolean,
    imports: boolean,
    typeRefs: boolean,
    namespaceImport: boolean,
    parameter: boolean,
    bindingElement: boolean,
    propertyAssignment: boolean,
    propertySignature: boolean,
    classDeclaration: boolean,
    setAccessor: boolean,
    getAccessor: boolean,
    application: boolean,
    sourceFile: boolean
}

export interface MetaData {
    appName?: string,
    commit?: string,
    repository?: string,
    appRepoName?: string,
    uuid?: string
}

export interface InitialState {
    filters: any,
    nodesDataList: any,
    linksDataList: any,
    linkDataDict: any,
    linkOptions: LinkOptions,
    reload: boolean,
    metaData?: MetaData,
    selectedNodesKeys: []
}


const initialState: InitialState | null = {
    filters: null,
    nodesDataList: {},
    linksDataList: {},
    linkDataDict: {},
    linkOptions: {
        flowOption: 'Full flow',
        constructors: true,
        methods: true,
        properties: true,
        functions: true,
        variables: true,
        getAccessors: true,
        parameters: true,
        enums: true,
        imports: true,
        typeRefs: true,
        namespaceImport: true,
        parameter: true,
        bindingElement: true,
        propertyAssignment: true,
        propertySignature: true,
        classDeclaration: true,
        setAccessor: true,
        getAccessor: true,
        application: true,
        sourceFile: true,
    },
    reload: false,
    selectedNodesKeys: []
};

export const sysmapReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case "SYS_MAP_FILTER":
            return {...state,
                filters: action.payload,};
        case "UPDATEEXPANDEDLIST":
            return {
                ...state,
                reload: true,
                nodesDataList: {
                    ...state.nodesDataList,
                    ...action.payload.nodesDataList,
                },
                linkDataDict: {
                    ...state.linkDataDict,
                    ...action.payload.linkDataDict
                }
            };
        case "UPDATEAPPNAMES":
            return {
                ...state,
                // metaData: [action.payload.appNames],
                commits: [action.payload.commits]
            }
        case "RESETRELOAD":
            return {
                ...state,
                reload: false
            };
        case 'UPDATE_LINK_OPTIONS':
            return {
                ...state,
                linkOptions: {...state.linkOptions, ...action.payload},
                reload: true
            }
        case "UPDATE_APPS":
            return {
                ...state,
                metaData: action.payload.metaData
            }
        case "UPDATE_SELECTED_NODES":
            return {
                ...state,
                selectedNodesKeys: action.payload.selectedNodesKeys
            }
        default:
            return state
    }
};
