import React from 'react';
import {Loader} from '../../../components/loader';

export const GetStartedModal = (props: any) => {
    return (<>
            <div className="flex justify-center">
                <div className="relative">
                    <div className="px-8 py-6">
                        {/* !isFetchingSysmap && */}
                        <>
                            <p className="text-md font-normal text-gray-900 mb-6 text-center">
                                <strong>*</strong> We use GitHub to connect your organization with our system. Please authenticate with
                                GitHub to continue.
                            </p>
                            {!props.isLoggingIn && (
                                <div className="flex justify-center">
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={() => {
                                            props.handleSetPage('setup_codemaps');
                                        }}
                                    >
                                        <div className="flex items-center p-4 font-bold rounded-lg bg group hover:shadow bg-gray-800 hover:bg-gray-600 text-white hover:cursor-pointer">
                                            <img width="22" src="/github-logo.png" alt="GitHub Logo" className="mr-3"/>
                                            Get Started
                                        </div>
                                    </a>
                                </div>
                            )}
                            {props.isLoggingIn && <Loader message="Authenticating ..." />}
                        </>
                    </div>
                </div>
            </div>
        </>
    )
}
