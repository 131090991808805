import {Store} from "@reduxjs/toolkit";
import {InitialState} from "../reducers/sysmap";

export const linksDataDictSelector = (store:Store) => {
    let linksData ={} as any
    const linksStore = {...(store as any).getState().sysmap.linkDataDict}
    Object.keys(linksStore || {}).forEach(key => {
      linksData = {...linksData, ...(JSON.parse(JSON.stringify(linksStore[key])))}
    });
    return linksData
}

export const flowOptionDataSelector = (store: Store) => {
    return Object.values({...(store as any).getState().sysmap.linkOptions.flowOption}).join('');
}

// Get Options for D-types to show
export const checkboxOptionDataSelector = (store: Store) => {
    let linkOptions = {...(store as any).getState().sysmap.linkOptions};
    delete linkOptions.flowOption;
    return linkOptions;
}

// Get list of selected nodes
export const getSelectedNodesKeys = (store: any) => {
    return Object.values(store.getState().sysmap.selectedNodesKeys);
};


// Get App Names/ Commits
export const appNamesSelector = (store: Store) => {
    let appName = {...(store as any).getState().sysmap.appNames};
    let commit = {...(store as any).getState().sysmap.commits}
    return {'apps': appName[0], 'commits': commit[0]}
}
