import {toast} from "react-toastify"

/**
 * Following function is used to set the opacity
 * property to 1
 * @param node an object that has opacity property
 * @returns void
 */
export const setFullOpacity = (node: any) => {
    node.opacity = 1
}

export const setSelectedOpacity = (diagram: any, visited: any) => {
    diagram.nodes.each((node: any) => { // Set opacity
        if (visited[node.data.key]){
            node.opacity = 1
        }
    })
}

/**
 * Following function is used to set the opacity
 * property to 0
 * @param node an object that has opacity property
 * returns void
 */
export const resetFullOpacity = (node: any) => {
    node.opacity = 0
}

/**
 * Following function is used to toggle flag
 * @param flag boolean
 * @return flag
 */
export const flagToggle = (flag: boolean) => (!flag)

/**
 * Following function is used to amend name properly
 * @param name element name ot be dispalyed
 * @return string
 */
export const groupLevelTextBinding = (app: any) => {
    return (`${app.appName} \n ${app.repository} ${app.branch} ${app.commit.slice(0, 7)}`)
}

/**
 * Following function is used to pass data as passed by default
 * @param text string
 * @return string
 */
export const defaultTextBind = (text: string) => (text)

/**
 * Following function is used to amend string properly
 * using passed data object
 * @param data object
 * @return string
 */
export const tooltipTextBind = (data: any) => (`Name: ${data.name} \n Type: ${data.kindName} \n Parent File: ${data.fileName}`)

/**
 * Following function is used to amend string properly
 * using passed data object
 * @param data object
 * @return string
 */
export const kindNameTextBinding = (data: any) => (`${data.kindName}: ${data.name}`)

/**
 * Following function is used to deal with download
 * using passed data object
 * @param blob object
 * @return void
 */
export const myCallback = (blob: any) => {
    var csvURL = window.URL.createObjectURL(blob);
    let tempLink = document.createElement('a');
    tempLink.href = csvURL;

    tempLink.setAttribute('download', 'blob.svg');
    tempLink.click();
}

export const findMultipleOccurences = (text: string, word: string) => {
    if (text == null || word == null) {
        return []
    }
    // Remove special chars
    const escapedWord = word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    // Using exec() with a regular expression and global flag
    const matchesFound = [];
    const regex = new RegExp(escapedWord, 'g');
    let match;
    while ((match = regex.exec(text)) !== null) {
        matchesFound.push(match.index);
        if (regex.lastIndex === match.index) regex.lastIndex++
    }
    return matchesFound
}

/**
 * this function gets all the links corresponding to the
 * given node, first from the linkDataArray, then from the new
 * linksDataDict. The reason for checking both these stores is
 * to verify if the linksDataDict contains correct results or not
 * arg1: the node key
 * arg2: linksDataArray
 * arg3: linksDataDict
 */
export const getLinksForNode = (key: string, linksDataArray: Array<any>, linksDataDict: any) => {
    const linksfromLinksArray = [] as any
    let linksfromLinksDict = [] as any
    linksDataArray.forEach((link: any) => {
        if (link.to === key || link.from === key) {
            linksfromLinksArray.push(link);
        }
    })
    if (linksDataDict[key]) {
        linksfromLinksDict = [...linksDataDict[key].outgoingLinks, ...linksDataDict[key].incomingLinks]
    }
    return {linksFromLinksDictionary: linksfromLinksDict, linksfromLinksArray}
}


export const isLinkOfRequiredType = (linkType: string) => {
    if (linkType === "FunctionDeclaration" || linkType === "MethodDeclaration"
        || linkType === "PropertyDeclaration" || linkType === "VariableDeclaration"
    ) {
        return true
    } else {
        return false
    }
}

export const showSuccessToast = (message: string) => {
    toast(message, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
        theme: "colored",
        type: "success"
    })
}
