import React, {useCallback} from "react";



export const demoUserData = {
    "uid": "AUTOGEN",
    "emailVerified": false,
    "isAnonymous": false,
    "photoURL": "https://avatars.githubusercontent.com/u/144456743?v=4",
    "providerData": [
        {
            "providerId": "github.com",
            "uid": "11111111",
            "displayName": "Demo",
            "email": null,
            "phoneNumber": null,
            "photoURL": "https://avatars.githubusercontent.com/u/144456743?v=4"
        }
    ],
}


export const demoGhToken = 'ghu_hA8UsGqtuXBSOg11A4gsUqQ1Vxi9Tx3TCK0j'


export const demoMetaData =[
    {
        "uuid": "5c8e66ce-1f7c-49f4-a823-bb0084d624be",
        "appName": "payment-wallet",
        "appType": "api",
        "repository": "payment-wallet",
        "branch": "main",
        "commit": "7a9a12b3c5174da0db7164202a95142095fe0786",
        "appRepoName": "payment-wallet-payment-wallet"
    },
    {
        "uuid": "27a77e48-447e-41b7-bd9e-88d9e826566d",
        "appName": "payment-processor",
        "appType": "api",
        "repository": "payment-processor",
        "branch": "main",
        "commit": "292961c7431064362ab4ca3608f100fbb7750745",
        "appRepoName": "payment-processor-payment-processor"
    },
    {
        "uuid": "485d2f18-a2d8-49c9-b80b-02a7116caf6f",
        "appName": "payment-engine",
        "appType": "api",
        "repository": "payment-engine",
        "branch": "main",
        "commit": "72530f215cc70362c5a29c3d32efafa035805790",
        "appRepoName": "payment-engine-payment-engine"
    }
]
