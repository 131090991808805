import { Button, Form, Input } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { OverlayLoader } from '../../components/overlay-loader';
import { RootState } from '../../store/store';
import {useSelector} from 'react-redux';
import { useMutation } from 'react-query';
import { saveOrg, saveRepo } from '../../services/github-service';
import { Loader } from '../../components/loader';

export const CreateMap = () => {
    const {mutate:mutateOrg, isSuccess:isSuccessOrg, isError:isErrorOrg, data:dataOrg, error:errorOrg, isLoading:isLoadingOrg} = useMutation(saveOrg);
    const {mutate:mutateRepo, isSuccess:isSuccessRepo, isError:isErrorRepo, data:dataRepo, error:errorRepo, isLoading:isLoadingRepo} = useMutation(saveRepo);

    const selectedOrgName = useSelector<RootState>((state)=> state?.user?.org?.login);
    const [repoName, setRepoName] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);
    const handleSubmit = () => {
        mutateRepo({org:selectedOrgName, name:repoName});
        setIsSubmitted(true);
    }
    const registerAnotherRepo = () => {
        setRepoName("");
        setIsSubmitted(false);
    }
    useEffect(()=>{
        mutateOrg({name:selectedOrgName});
    },[])


    return (
    <div className=" min-h-screen flex justify-center pb-9 pt-16">
        {isLoadingOrg && <OverlayLoader message=" "/> }
        <div className=" w-2/3 shadow-md bg-gray-100 rounded-md px-8 py-7 ">
            <h1 className=' text-center text-xl mb-7'> Get started with codemaps now !! </h1>
            <div>
                <h3 className=' text-center'>Enter repository name</h3>
                <div className=' w-3/5 mx-auto'>
                <Input
                onChange={(e)=>{setRepoName(e.target.value)}}
                value={repoName}
                placeholder="Enter repository name"
                />
                </div>
            </div>
            <div className=' py-5'>
                <h3 className='mb-3'>Enter your repository name and then copy the code below and add it to your repository's github runner. This will parse your repository on newer commits and generate a codemap for it</h3>
                <SyntaxHighlighter
                    language="yaml"
                    style={dark}
                    lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
                    wrapLines={false}
                >
                    {" name: "+repoName + "\n#name: Push to GCR GitHub Action \n# on: [push]\n# jobs:\n#   build-and-push-to-gcr:\n#     runs-on: ubuntu-latest\n#     steps:      \n#       - uses: actions/checkout@v3\n#       - name: Authenticate to Google Cloud\n#         id: auth\n#         # uses: google-github-actions/auth@v0\n#         # with:\n#         #   # workload_identity_provider: projects/code-maps-3ffa5/locations/global/workloadIdentityPools/the-workload-pool/providers/the-provider\n#         #   service_account: github-action-codemapsrunner@code-maps-3ffa5.iam.gserviceaccount.com\n#         uses: RafikFarhad/push-to-gcr-github-action@v5-beta\n#         with:\n#           gcloud_service_key: '${{ secrets.GCLOUD_SERVICE_KEY }} # can be base64 encoded or plain text || not needed if you use google-github-actions/auth\n#           registry: gcr.io\n#           project_id: code-maps-3ffa5\n#           image_name: backend\n#           # image_tag: latest\n#           dockerfile: ./docker/Dockerfile\n#           context: ./docker"}
                </SyntaxHighlighter>
            </div>
            {!isSubmitted &&
                <div className=' flex justify-center py-5'>
                    <Button onClick={handleSubmit}>Submit</Button>
                    {isLoadingRepo && <Loader/>}
                </div>
            }
            {isSubmitted &&
                <div className='flex justify-between'>
                    <Button onClick={registerAnotherRepo}>Register another repository</Button>
                </div>
            }
        </div>
    </div>
    )
}

