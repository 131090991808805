import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useQuery } from 'react-query';
import {useSelector} from 'react-redux';
import {RootState} from '../../../store/store'
import {useDispatch} from 'react-redux';
import { toast } from 'react-toastify';
import { getRepos, getRepoDetails} from '../../../services/github-service';
import { Button, Divider } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { OverlayLoader } from '../../../components/overlay-loader';


export const ReposList = (props:any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector<RootState>((state)=> state?.user);
  const selectedOrg = useSelector<RootState>((state)=> state?.user?.org);
  const selectedOrgName = useSelector<RootState>((state)=> String(state?.user?.org?.login) || '');
  const {isLoading, isError, error, data} = useQuery('repos', ()=>getRepos(String(selectedOrgName)));
  const [expandedRepos, setExpandedRepos] = useState<any>({});
  const [repoDetails, setRepoDetails] = useState<any>({});

  useEffect(()=>{
    if(selectedOrg){
       //navigate(`/canvas/?mapID=${selectedOrg}`)
    }
  },[selectedOrg]);

  useEffect(()=>{
    if(data){
        console.log("org fetched:", data );
    }
  },[data]);

  useEffect(()=>{
    if(error){
      toast( `Something went wrong. Could not fetch repositories for ${selectedOrgName} `, {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
        theme: "colored",
        type: "error"
      })
    }
  },[isError]);

  const getDetails = async (name:string, owner:string) => {
        setExpandedRepos({...expandedRepos, [name]:true});
        if (!repoDetails[name]) {
            const data:any= await getRepoDetails(name, owner);
            if(data){
                console.log(`data for repo ${name}:`, data)
                setRepoDetails({...repoDetails, [name]: data})

            }
        }
  }
  return (
    <>
        {isLoading && <OverlayLoader/>}
        {data &&
            <div className='pt-2 px-10'>
                <div className='pt-10 px-10 flex flex-row justify-between'>
                    <h2>Organization: {String(selectedOrgName)}</h2>
                    <Button onClick={()=>navigate(`/canvas?mapID=${selectedOrgName}`)}>Go to map</Button>
                </div>
                <Divider/>
                {data.map((entry:any, index:number) => (
                    <>
                        <div key={index} className=' px-2 rounded-sm flex justify-start hover:bg-slate-100 cursor-pointer mb-3 align-middle' onClick={()=>getDetails(entry.name, entry.owner.login)}>
                            <h3 className=' align-middle w-9'>{index+1}:</h3>
                            <div className=' w-2/3 px-7'>
                                <h2>{String(entry.name)}</h2>
                                <p>{entry.description}</p>
                            </div>
                            <span className=' mr-1 '>Commit History <FontAwesomeIcon icon={faAngleDown}/></span>
                        </div>
                        {expandedRepos[entry.name] && repoDetails[entry.name] &&
                            repoDetails[entry.name].map((commit:any)=>
                                <p><a href={commit.commit.url}>{`By: ${commit.commit.author.name}, ${commit.commit.author.email} on ${commit.commit.author.date}  `} </a></p>
                            )
                        }
                    </>

                ))
                }
            </div>
        }
    </>
  )
}
