import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { githubReducer } from './reducers/github'
import { sysmapReducer } from './reducers/sysmap'
import { userReducer } from './reducers/user'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['user','org', 'githubtoken'] // only navigation will be persisted
};
export const rootReducer = combineReducers({
  github:githubReducer,
  sysmap:sysmapReducer,
  user:persistReducer(persistConfig, userReducer)
});

export const store = configureStore({reducer: rootReducer});
export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
