import * as go from 'gojs';
import {produce} from 'immer';
import * as React from 'react';
import '../../css/App.css';
import {DiagramWrapper} from './DiagramWrapper';
import {useBeforeUnload} from "react-router-dom";
import {useEffect, useState} from 'react';
import {OverlayLoader} from '../../components/overlay-loader';
import {useSelector, useDispatch} from 'react-redux';
import {RootState} from '../../store/store';
import {getSysmap} from '../../services/github-service';
import {toast} from 'react-toastify';
import ErrorPage from "../generic_error";

import {filterNodeDataArray} from "../../utils/diagram_flows";

interface AppState {
    appInfo?: string,
    organization?: string,
    linkDataDict?: any,
    nodeDataArray?: Array<go.ObjectData>;
    linkDataArray?: Array<go.ObjectData>;
    modelData?: go.ObjectData;
    selectedData?: go.ObjectData | null;
    repositories?: any;
    appRepos?: any;
    skipsDiagramUpdate?: boolean;
}

export const Canvas = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [appState, setAppState] = React.useState<AppState>({
        nodeDataArray: [],
        linkDataDict: {},
        linkDataArray: [],
        modelData: {
            canRelink: false
        },
        selectedData: null,
        skipsDiagramUpdate: false
    });

    /** Returns AppState Node Data Array Based on Checked List
     * Matches items KindName
     * */


    useSelector<any>((state: any) => {
        if (state.sysmap.reload) {
            let linklist: any = []
            let nodeList: any = []
            let appsLinksDicts: any = {}
            let checkedOptions : any = {}
            checkedOptions = {... state.sysmap.linkOptions}
            Object.keys(state.sysmap.nodesDataList || {}).forEach(key => {
                nodeList = [...nodeList, ...(JSON.parse(JSON.stringify(state.sysmap.nodesDataList[key])))]
            });
            Object.keys(state.sysmap.linksDataList || {}).forEach(key => {
                linklist = [...linklist, ...(JSON.parse(JSON.stringify(state.sysmap.linksDataList[key])))]
            });
            Object.keys(state.sysmap.linkDataDict || {}).forEach(key => {
                appsLinksDicts = {...appsLinksDicts, ...(JSON.parse(JSON.stringify(state.sysmap.linkDataDict[key])))}
            });
            const filteredArray = filterNodeDataArray(nodeList, checkedOptions);
            setAppState({
                ...appState,
                nodeDataArray: filteredArray,
                linkDataArray: linklist,
                linkDataDict: appsLinksDicts
            })
            dispatch({type: 'RESETRELOAD'})
        }
        return true
    });

    const [isError, setIsError] = useState(false);
    const githubToken = useSelector<RootState>((state) => state?.user?.githubtoken);

    let mapNodeKeyIdx: Map<go.Key, number>;
    let mapLinkKeyIdx: Map<go.Key, number>;

    useBeforeUnload(
        React.useCallback(
            (event) => {
                event.preventDefault();
                event.returnValue = "message";
            },
            []
        ),
        {capture: true}
    );

    /** Update AppName and Commit Data in Store */
    React.useEffect(() => {
        if (appState.appRepos) {
            let payload: { appNames: string[], commits: string[], uuid: string[] } = {
                appNames: [],
                commits: [],
                uuid: []
            };
            Object.keys(appState.appRepos).forEach(key => {
                payload.appNames.push(appState.appRepos[key].appName)
                payload.commits.push(appState.appRepos[key].commit)
                payload.uuid.push(appState.appRepos[key].commit)
            })
            dispatch({type: "UPDATEAPPNAMES", payload: payload})
        }
    }, [appState])

    /** Updates Data in Store By Fetching*/
    useEffect(() => {
        (async () => {
            if (appState.organization && githubToken) {
                try {
                    setLoading(true);
                    const codemapsData = await getSysmap(appState.organization, []);
                    setLoading(false);
                    dispatch({
                        type: "UPDATE_APPS",
                        payload: {
                            metaData: codemapsData.repositories
                        }
                    })
                    dispatch({
                        type: 'UPDATEEXPANDEDLIST',
                        payload: {
                            nodesDataList: {'firstfetch': JSON.parse(JSON.stringify(codemapsData.nodeDataArray))},
                            linksDataList: {},
                            linkDataDict: {}
                        }
                    })
                } catch (err: any) {
                    setLoading(false);
                    setIsError(true);
                    toast(err.response, {
                        position: "top-right",
                        hideProgressBar: false,
                        autoClose: false,
                        closeOnClick: true,
                        progress: undefined,
                        theme: "colored",
                        type: "error"
                    })
                    console.error(err);
                }
            }
        })();
    }, [appState.organization, githubToken])

    const setLinkResult = (result: any) => {
        const repoName = result.appName;
        const appRepoName = result.appRepoName;
        if (repoName) {
            dispatch({
                type: 'UPDATEEXPANDEDLIST',
                payload: JSON.parse(JSON.stringify({
                    reload: true,
                    appRepoName: appRepoName,
                    repoName: repoName,
                    nodesDataList: {[appRepoName]: [...JSON.parse(JSON.stringify(result.nodeDataArray))]},
                    linksDataList: {[appRepoName]: {...result.linkDataDict}},
                    linkDataDict: {[appRepoName]: {...result.linkDataDict}}
                }))
            })
        }
    }

    // Set indexes ?
    useEffect(() => {
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        let mapId = params.get('mapID');
        if (mapId) {
            setAppState(prevState => ({...prevState, organization: mapId ?? undefined}));
            mapNodeKeyIdx = new Map<go.Key, number>();
            mapLinkKeyIdx = new Map<go.Key, number>();
            refreshNodeIndex(appState.nodeDataArray!);
            refreshLinkIndex(appState.linkDataArray!);
        }

    }, [appState.nodeDataArray, appState.linkDataArray])

    /**
     * Update map of node keys to their index in the array.
     */
    function refreshNodeIndex(nodeArr: Array<go.ObjectData>) {
        mapNodeKeyIdx.clear();
        nodeArr.forEach((n: go.ObjectData, idx: number) => {
            mapNodeKeyIdx.set(n.key, idx);
        });
    }

    /**
     * Update map of link keys to their index in the array.
     */
    function refreshLinkIndex(linkArr: Array<go.ObjectData>) {
        mapLinkKeyIdx.clear();
        linkArr.forEach((l: go.ObjectData, idx: number) => {
            mapLinkKeyIdx.set(l.key, idx);
        });
    }

    /**
     * Handle any relevant DiagramEvents, in this case just selection changes.
     * On ChangedSelection, find the corresponding data and set the selectedData state.
     * @param e a GoJS DiagramEvent
     */
    function handleDiagramEvent(e: go.DiagramEvent) {
        const name = e.name;
        switch (name) {
            case 'ChangedSelection': {
                const sel = e.subject.first();
                setAppState(
                    produce((draft: AppState) => {
                        if (sel) {
                            if (sel instanceof go.Node) {
                                const idx = mapNodeKeyIdx.get(sel.key);
                                if (idx !== undefined && idx >= 0) {
                                    const nd = draft.nodeDataArray![idx];
                                    draft.selectedData = nd;
                                }
                            } else if (sel instanceof go.Link) {
                                const idx = mapLinkKeyIdx.get(sel.key);
                                if (idx !== undefined && idx >= 0) {
                                    const ld = draft.linkDataArray![idx];
                                    draft.selectedData = ld;
                                }
                            }
                        } else {
                            draft.selectedData = null;
                        }
                    })
                );
                break;
            }
            default:
                break;
        }
    }

    /**
     * Handle GoJS model changes, which output an object of data changes via Model.toIncrementalData.
     * This method iterates over those changes and updates state to keep in sync with the GoJS model.
     * @param obj a JSON-formatted string
     */
    function handleModelChange(obj: go.IncrementalData) {
        const insertedNodeKeys = obj.insertedNodeKeys;
        const modifiedNodeData = obj.modifiedNodeData;
        const removedNodeKeys = obj.removedNodeKeys;
        const insertedLinkKeys = obj.insertedLinkKeys;
        const modifiedLinkData = obj.modifiedLinkData;
        const removedLinkKeys = obj.removedLinkKeys;
        const modifiedModelData = obj.modelData;
        const modifiedNodeMap = new Map<go.Key, go.ObjectData>();
        const modifiedLinkMap = new Map<go.Key, go.ObjectData>();
        setAppState(
            produce((draft: AppState) => {
                let narr = draft.nodeDataArray;
                if (modifiedNodeData) {
                    modifiedNodeData.forEach((nd: go.ObjectData) => {
                        modifiedNodeMap.set(nd.key, nd);
                        const idx = mapNodeKeyIdx.get(nd.key);
                        if (idx !== undefined && idx >= 0) {
                            narr![idx] = nd;
                            if (draft.selectedData && draft.selectedData.key === nd.key) {
                                draft.selectedData = nd;
                            }
                        }
                    });
                }
                if (insertedNodeKeys) {
                    insertedNodeKeys.forEach((key: go.Key) => {
                        const nd = modifiedNodeMap.get(key);
                        const idx = mapNodeKeyIdx.get(key);
                        if (nd && idx === undefined) {  // nodes won't be added if they already exist
                            mapNodeKeyIdx.set(nd.key, narr!.length);
                            narr!.push(nd);
                        }
                    });
                }
                if (removedNodeKeys) {
                    narr = narr!.filter((nd: go.ObjectData) => {
                        if (removedNodeKeys.includes(nd.key)) {
                            return false;
                        }
                        return true;
                    });
                    draft.nodeDataArray = narr;
                    refreshNodeIndex(narr);
                }

                let larr = draft.linkDataArray;
                if (modifiedLinkData) {
                    modifiedLinkData.forEach((ld: go.ObjectData) => {
                        modifiedLinkMap.set(ld.key, ld);
                        const idx = mapLinkKeyIdx.get(ld.key);
                        if (idx !== undefined && idx >= 0) {
                            larr![idx] = ld;
                            if (draft.selectedData && draft.selectedData.key === ld.key) {
                                draft.selectedData = ld;
                            }
                        }
                    });
                }
                if (insertedLinkKeys) {
                    insertedLinkKeys.forEach((key: go.Key) => {
                        const ld = modifiedLinkMap.get(key);
                        const idx = mapLinkKeyIdx.get(key);
                        if (ld && idx === undefined) {  // links won't be added if they already exist
                            mapLinkKeyIdx.set(ld.key, larr!.length);
                            larr!.push(ld);
                        }
                    });
                }
                if (removedLinkKeys) {
                    larr = larr!.filter((ld: go.ObjectData) => {
                        if (removedLinkKeys.includes(ld.key)) {
                            return false;
                        }
                        return true;
                    });
                    draft.linkDataArray = larr;
                    refreshLinkIndex(larr);
                }
                // handle model data changes, for now just replacing with the supplied object
                if (modifiedModelData) {
                    draft.modelData = modifiedModelData;
                }
                draft.skipsDiagramUpdate = true; // the GoJS model already knows about these updates
            })
        );
    }
    return (
        <div>
            {loading ?
                < OverlayLoader
                    message="Fetching data ..."
                />
                :
                <div className='relative'>
                    {
                        appState.nodeDataArray?.length! > 0 ? (
                            <DiagramWrapper
                                loading={loading}
                                nodeDataArray={appState.nodeDataArray}
                                linkDataDict={appState.linkDataDict}
                                linkDataArray={appState.linkDataArray}
                                appRepoByKey={appState.appRepos}
                                modelData={appState.modelData}
                                skipsDiagramUpdate={appState.skipsDiagramUpdate}
                                onDiagramEvent={handleDiagramEvent}
                                onModelChange={handleModelChange}
                                setLinkResult={setLinkResult}
                                showSidePanel={true}
                                showMiniMap={true}
                            />
                        ) : <></>
                    }
                </div>
            }
            {
                isError
                &&
                <ErrorPage></ErrorPage>
            }
        </div>
    )
}
