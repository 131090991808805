import { useQuery } from "react-query";
import { getRepos } from "../../services/github-service";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

export const Repos = () => {
  
    const org:any = useSelector<RootState>((state)=> state?.user?.org?.login || null);
    useEffect(()=>{
        //http://127.0.0.1:5001/code-maps-3ffa5/us-central1/app/sysmaps/
        //https://backend-codemaps-a4yqkukaqa-uc.a.run.app/sysmaps/
        if (org) {
          getRepos(org)
        }
      },[org])
  return (
    <h1>Hello</h1>
  )
}
